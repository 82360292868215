import difference from 'lodash/difference';
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import some from 'lodash/some';
import union from 'lodash/union';
import values from 'lodash/values';

/////////////////////////////

function UserManagementController(
    $scope,
    $timeout,
    Config,
    Customer,
    Feed,
    FeedUserSubscriptionFactory,
    FormValidation,
    LxDataTableService,
    LxDialogService,
    LxDropdownService,
    LxNotificationService,
    Translation,
    User,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The date format for the backend user format.
     *
     * @type {string}
     */
    const _DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS';

    /**
     * A list of all the ids of the non-technical feeds the current user belongs to (i.e. community feeds).
     *
     * @type {Array}
     */
    let _originalNonTechnicalFeedKeys = [];

    /**
     * Contains the current search filters.
     *
     * @type {Object}
     */
    let _currentFilter;

    /**
     * Contains a template for empty filter.
     *
     * @type {Object}
     */
    const _emptyFilter = {
        accountType: [],
        email: undefined,
        feedKeys: [],
        firstName: undefined,
        lastName: undefined,
        fullName: undefined,
        showHidden: true,
        status: undefined,
    };

    /**
     * Contains the template for an empty user.
     *
     * @type {Object}
     */
    const _emptyUser = {
        accountType: Config.USER_CREATE_DEFAULT_ACCOUNT_TYPE,
        active: true,
        feedKeys: [],
        firstName: '',
        lastName: '',
        fullName: '',
        subscriptions: [],
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The headers of the tables that contains the list of roles.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.DATA_TABLE_HEADERS = [
        {
            format: (row) => {
                return `
                    <div class="lumx-grid lumx-grid--orientation-horizontal lumx-grid--h-align-center">
                        <img class="lumx-spacing-margin-right-big user-profile-img" src=${vm.User.getProfilePicture(row)}
                        />
                        <span>${row.email}</span>
                    </div>
                `;
            },
            label: Translation.translate('ADMIN_USER_DIRECTORY_USER_EMAIL'),
            name: 'email',
        },
    ];

    /**
     * The id of the "roles" data table.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.DATA_TABLE_ID = 'usersDataTable';

    /**
     * The parameters for getting the list of feeds with only manually provisioning mode.
     *
     * @type {Object}
     */
    vm.provisioningMode = {};

    /**
     * The dropdown filter identifier.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.DROPDOWN_ID = 'admin-filters';

    /**
     * The dropdown filter opening target.
     *
     * @type {string}
     * @constant
     * @readonly;
     */
    vm.DROPDOWN_TARGET = 'admin-search-field';

    /**
     * The list key for the feeds list.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.FEED_LIST_KEY = 'user-directory-feeds';

    /**
     * The list key for the users list.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.LIST_KEY = 'user-directory-admin';

    /**
     * The id of the user directory settings dialog.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.SETTINGS_DIALOG_ID = 'user-directory-settings';

    /**
     * The user being edited/created.
     *
     * @type {Object}
     */
    vm.currentUser = {};

    /**
     * Indicates if we are displaying the filter in the data table or not.
     *
     * @type {boolean}
     */
    vm.displayFilter = false;

    /**
     * The minimum date settable as expiration date for an user.
     *
     * @type {Date}
     */
    vm.expirationMinDate = new Date().setHours(24, 0, 0, 0);

    /**
     * Contains the current filters of the users list.
     *
     * @type {Object}
     */
    vm.filter = undefined;

    /**
     * The restricted feeds, filtered by google groups and without "feed all".
     *
     * @type {Object}
     */
    vm.filteredFeeds = undefined;

    /**
     * Contains the user directory settings form.
     *
     * @type {Object}
     */
    vm.form = {};


    /**
     * Contains the generated password (if any) for the edited/created user.
     *
     * @type {Object}
     */
    vm.generatedPassword = {
        status: false,
        value: undefined,
    };

    /**
     * Indicates if there is an active filter applied to the list of users.
     *
     * @type {boolean}
     */
    vm.hasActiveFilter = false;

    /**
     * Indicates if we are creating a new user or editing an existing one.
     *
     * @type {boolean}
     */
    vm.isNewUser = false;

    /**
     * Indicates if the list of user feed subscriptions is in progress.
     *
     * @type {boolean}
     */
    vm.isUserSubscriptionListInProgress = false;

    /**
     * Contains the selected users.
     *
     * @type {Array}
     */
    vm.selectedItems = [];

    /////////////////////////////

    /*
     * Services and utilities.
     */
    vm.Config = Config;
    vm.Customer = Customer;
    vm.Feed = Feed;
    vm.FormValidation = FormValidation;
    vm.Translation = Translation;
    vm.User = User;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Format the currently in edition user to the backend format.
     */
    function _formatCurrentUser() {
        vm.currentUser.status = vm.currentUser.active ? 'enabled' : 'disabled';
        vm.currentUser.isHidden = !vm.currentUser.isVisible;

        const allFeedKeys = map(vm.currentUser.subscriptions, 'feed');
        const technicalFeedKeys = difference(allFeedKeys, _originalNonTechnicalFeedKeys);

        // We've played with the non-technical feeds in the UI but we want to save all the feeds (technical or not).
        const subscriptionFeedKeys = union(technicalFeedKeys, vm.currentUser.nonTechnicalFeedKeys);

        vm.currentUser.subscriptions = map(subscriptionFeedKeys, (feedKey) => ({
            feed: feedKey,
        }));
    }

    /**
     * Reset the filters to the empty filters.
     */
    function _resetFilters() {
        vm.filter = angular.copy(_emptyFilter);
        _currentFilter = angular.copy(_emptyFilter);
    }

    /**
     * Update the selected users list when a row is toggled in the data table.
     *
     * @param {Event}  evt          The select/unselect event from the data table.
     * @param {string} dataTableId  The data table identifier.
     * @param {Array}  selectedRows The selected rows.
     */
    function _updateSelectedUsers(evt, dataTableId, selectedRows) {
        if (dataTableId === vm.DATA_TABLE_ID) {
            vm.selectedItems = selectedRows;
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Create an empty new user and open the user edition dialog.
     */
    function addUser() {
        vm.isNewUser = true;
        vm.currentUser = angular.fastCopy(_emptyUser);
        vm.currentUser.isVisible = true;

        Utils.waitForAndExecute(`#${vm.SETTINGS_DIALOG_ID}`);
    }

    /**
     * Check if the user's email is editable.
     * To be editable, the user must be an external user.
     *
     * @return {boolean} If the user's email is editable.
     */
    function canEditEmail() {
        if (vm.isNewUser) {
            return true;
        }

        if (angular.isUndefinedOrEmpty(vm.currentUser)) {
            return false;
        }

        return User.isExternal(vm.currentUser);
    }

    /**
     * Set the user to edit and open the user edition dialog.
     *
     * @param {Object} user The user to edit.
     */
    function editUser(user) {
        vm.provisioningMode = {
            provisioningMode: "MANUAL"
        };
        if (angular.isUndefinedOrEmpty(get(user, 'id'))) {
            return;
        }

        vm.isUserSubscriptionListInProgress = true;

        User.get(
            {
                uid: user.id,
            },
            (response) => {
                // Doing an extend in case the user subscription list finished first (to avoid override feedKeys).
                angular.extend(vm.currentUser, angular.fastCopy(response));

                /**
                 * If the expiration date has never been defined, the backend
                 * will return "null".
                 * However the date picker doesn't allow null as value and will break.
                 * To fix that, set the value as undefined instead
                 */
                if(vm.currentUser.expirationDate === null){
                    vm.currentUser.expirationDate = undefined;
                }

                // Check user status.
                vm.currentUser.active = response.status === 'enabled';
                vm.currentUser.isVisible = !vm.currentUser.isHidden;

                FeedUserSubscriptionFactory.list(
                    {
                        userId: user.id,
                    },
                    (feedResponse) => {
                        const feeds = feedResponse.items || [];

                        _originalNonTechnicalFeedKeys = map(feeds, 'id');

                        vm.currentUser.nonTechnicalFeedKeys = angular.fastCopy(_originalNonTechnicalFeedKeys);

                        Feed.getMulti(vm.currentUser.nonTechnicalFeedKeys, vm.FEED_LIST_KEY).then(() => {
                            vm.isUserSubscriptionListInProgress = false;
                        });
                    },
                    () => {
                        vm.isUserSubscriptionListInProgress = false;
                    },
                );

                Utils.waitForAndExecute(`#${vm.SETTINGS_DIALOG_ID}`);
            },
            undefined,
            vm.LIST_KEY,
        );
    }

    /**
     * Filter the users list.
     *
     * @param {Object} filters The filters to use to get the list.
     */
    function filterUser(filters) {
        const queryFields = {
            email: filters.email,
            feeds: flattenDeep(filters.feedKeys),
            firstName: filters.firstName,
            lastName: filters.lastName,
            fullName: filters.fullName,
            showHidden: true,
            types: map(filters.accountType, 'value'),
        };

        if (filters.status) {
            queryFields.status = filters.status.value;
        }

        vm.hasActiveFilter = some(values(omit(filters, 'showHidden')), angular.isDefinedAndFilled);

        _currentFilter = queryFields;

        User.filterize(queryFields);
    }

    /**
     * Generate a random password.
     */
    function generatePassword() {
        const password = vm.generatedPassword.status ? Utils.generatePassword() : undefined;

        vm.currentUser.password = password;
        vm.currentUser.rePassword = password;
        vm.generatedPassword.value = password;
    }

    /**
     * Open confirm dalog when deleting user(s).
     */
    function deleteSelectedUsers() {
        const hasSelectedOneUser = vm.selectedItems.length === 1;

        LxNotificationService.confirm(
            Translation.translate(`ADMIN.USERS_MANAGEMENT.DELETE_SELECTED_USER${hasSelectedOneUser ? '' : 'S'}`),
            Translation.translate(
                `ADMIN.USERS_MANAGEMENT.DELETE_SELECTED_USER${hasSelectedOneUser ? '' : 'S'}_DESCRIPTION`,
            ),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            (answer) => {
                if (!answer) {
                    LxDataTableService.unselectAll(vm.DATA_TABLE_ID);

                    return;
                }

                User.delMulti(
                    vm.selectedItems.map((item) => item.id),
                    () => {
                        LxDataTableService.unselectAll(vm.DATA_TABLE_ID);
                        LxNotificationService.success(
                            Translation.translate(
                                `ADMIN.USERS_MANAGEMENT.DELETE_SELECTED_USER${hasSelectedOneUser ? '' : 'S'}_SUCCESS`,
                            ),
                        );
                    },
                    Utils.displayServerError,
                    vm.LIST_KEY_ADMIN,
                );
            },
        );
    }

    /**
     * Open filters.
     *
     * @param {Event} evt The click event.
     */
    function openFilters(evt) {
        LxDropdownService.open(vm.DROPDOWN_ID, `#${vm.DROPDOWN_TARGET}`);

        evt.stopPropagation();
    }

    /**
     * Register the user edition form.
     *
     * @param {Object} scope The page scope where the form is stored.
     */
    function registerUserForm(scope) {
        $timeout(() => {
            vm.form.userForm = get(scope, 'userForm');
        });
    }

    /**
     * Save the currently in edition user.
     *
     * @param {Object} [userForm=<saved form>] The user edition form.
     */
    function saveUser(userForm) {
        userForm = userForm || vm.form.userForm;

        if (angular.isUndefinedOrEmpty(userForm)) {
            return;
        }

        if (userForm.$valid) {
            _formatCurrentUser();

            User.save(
                vm.currentUser,
                () => {
                    LxNotificationService.success(Translation.translate('ADMIN_USER_DIRECTORY_SAVE_SUCCESS'));

                    LxDialogService.close(vm.SETTINGS_DIALOG_ID);
                    User.filterize(_currentFilter);
                },
                Utils.displayServerError,
                vm.LIST_KEY,
            );
        } else {
            LxNotificationService.error(Translation.translate('ADMIN_USER_DIRECTORY_SAVE_ERROR'));

            FormValidation.setFormDirty(userForm);
        }
    }

    /**
     * Toggle the filters panel.
     */
    function toggleFilter() {
        vm.displayFilter = !vm.displayFilter;
    }

    /////////////////////////////

    vm.addUser = addUser;
    vm.canEditEmail = canEditEmail;
    vm.editUser = editUser;
    vm.filterUser = filterUser;
    vm.generatePassword = generatePassword;
    vm.deleteSelectedUsers = deleteSelectedUsers;
    vm.openFilters = openFilters;
    vm.registerUserForm = registerUserForm;
    vm.saveUser = saveUser;
    vm.toggleFilter = toggleFilter;

    /////////////////////////////

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the user directory settings closes, reset the controller.
     *
     * @param {Event}  evt      The dialog closes event.
     * @param {string} dialogId The id of the dialog that closes.
     */
    $scope.$on('lx-dialog__close-end', (evt, dialogId) => {
        if (dialogId === vm.SETTINGS_DIALOG_ID) {
            vm.currentUser = {};
            vm.isNewUser = false;
            vm.generatedPassword = {
                status: false,
                value: undefined,
            };
            _originalNonTechnicalFeedKeys = [];

            LxDataTableService.unselectAll(vm.DATA_TABLE_ID);
        }
    });

    /**
     * When a row is selected in the data table, update the selected users.
     */
    $scope.$on('lx-data-table__selected', _updateSelectedUsers);

    /**
     * When a row is deselected in the data table, update the selected users.
     */
    $scope.$on('lx-data-table__unselected', _updateSelectedUsers);

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any changes in the filters and refresh the list of users.
     * Debounce the refreshing so that we don't refresh to often.
     *
     * @param {Object} newValue The new filter object.
     * @param {Object} oldValue The old filter object.
     */
    $scope.$watch(
        'vm.filter',
        (newValue, oldValue) => {
            if (newValue !== oldValue) {
                filterUser(newValue);
            }
        },
        true,
    );

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        _resetFilters();

        User.filterize({
            showHidden: true,
        });
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('UserManagementController', UserManagementController);

/////////////////////////////

export { UserManagementController };
