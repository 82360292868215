import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import loFind from 'lodash/find';
import map from 'lodash/map';
import toArray from 'lodash/toArray';
import without from 'lodash/without';

import { FULL_DATE_SIX_FRACTIONAL_SECONDS } from 'common/constants';

import { angularApi } from '@lumapps/router/routers';
import { contentAnalytics } from '@lumapps/content-analytics/routes';
import { canViewContentAnalytics } from '@lumapps/content-analytics/ducks/selectors';
import { METRICS_CONTEXT_NAME } from '@lumapps/content-analytics/constants';
import { getAttributes } from '@lumapps/data-attributes';
import { addFrontVersionToUrl } from '@lumapps/router/utils';

function ContentAdminController(
    $scope,
    $state,
    $stateParams,
    $window,
    Config,
    ConfigTheme,
    Content,
    CustomContentType,
    Document,
    Feed,
    Instance,
    LxDropdownService,
    LxNotificationService,
    Metadata,
    Template,
    Translation,
    User,
    UserAccess,
    Utils,
    resolveCustomContentType,
    ReduxStore,
) {
    'ngInject';

    // This is here to trick the linter into thinking that the blocking resolve injection is used.
    angular.noop(resolveCustomContentType);

    /////////////////////////////

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The fields and properties to be listed in the response of the content.list endpoints.
     *
     * @type {Array}
     */
    const _PROJECTION = {
        items: {
            analyticsViewSum: true,
            archivers: true,
            authorDetails: {
                firstName: true,
                lastName: true,
            },
            createdAt: true,
            customContentType: true,
            customContentTypeDetails: true,
            customContentTypeTags: true,
            deleters: true,
            editors: true,
            endDate: true,
            featuredFeedKeys: true,
            feedKeys: true,
            id: true,
            isFeatured: true,
            lastRevision: true,
            metadata: true,
            publicationDate: true,
            publishers: true,
            renderingType: true,
            slug: true,
            startDate: true,
            status: true,
            mediaThumbnail: true,
            thumbnail: true,
            title: true,
            uid: true,
            updatedAt: true,
        },
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Chip colors according to content status.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    vm.CONTENT_STATUS_COLOR = {
        ARCHIVE: 'yellow',
        DRAFT: 'dark',
        LIVE: 'green',
        TO_VALIDATE: 'dark',
    };

    /**
     * The dropdown filter identifier.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.DROPDOWN_ID = 'admin-filters';

    /**
     * The dropdown filter opening target.
     *
     * @type {string}
     * @constant
     * @readonly;
     */
    vm.DROPDOWN_TARGET = 'admin-search-field';

    /**
     * The type of expiration filter to apply on the list of content.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    vm.EXPIRATION_TYPE = {
        ALREADY: 'already',
        SOON: 'soon',
    };

    /**
     * The list key to use for calls to the API.
     *
     * @type {string}
     * @constant
     */
    vm.LIST_KEY = 'content-admin-';

    /**
     * The available actions for one item.
     *
     * @type {Object}
     */
    vm.itemActions = {
        primary: [
            // Edit.
            {
                id: 'edit',
                action(item) {
                    $state.go('app.front.content-edit', { key: item.id });
                },
                icon: 'pencil',
                label: Translation.translate('GLOBAL.EDIT'),
                visibility(item) {
                    return (
                        UserAccess.isEditor(item) ||
                        UserAccess.isUserAllowed('CUSTOM_CONTENT_EDIT', {
                            checkContent: true,
                            content: item,
                            customContentTypeId: item.customContentType,
                        })
                    );
                },
            },
            // View.
            {
                id: 'view',
                action(item) {
                    const url = $state.href('app.front.content-get', { slug: Translation.translate(item.slug) });
                    $window.open(addFrontVersionToUrl(url), "_self");
                },
                icon: 'eye',
                label: Translation.translate('VIEW'),
                visibility(item) {
                    return item.status.toLowerCase() === vm.Config.CONTENT_STATUS.LIVE.value.toLowerCase();
                },
            },
            // Analytics
            {
                id: getAttributes(METRICS_CONTEXT_NAME)({ element: 'actions', action: 'analytics' })['data-id'],
                action(item) {
                    angularApi.redirect(contentAnalytics(item.customContentType, item.id));
                },
                icon: 'chart-line',
                label: Translation.translate('GLOBAL.ANALYTICS'),
                visibility(item) {
                    return (
                        canViewContentAnalytics(ReduxStore.store.getState(), item.customContentType) &&
                        item.status.toLowerCase() === vm.Config.CONTENT_STATUS.LIVE.value.toLowerCase()
                    );
                },
            },
        ],
        secondary: [
            // Quick view.
            {
                action(item) {
                    vm.setActiveItem(item);
                },
                icon: 'information-outline',
                label: Translation.translate('GLOBAL.MORE_DETAILS'),
                visibility() {
                    return angular.isUndefinedOrEmpty(vm.activeItem);
                },
            },
            // Copy.
            {
                action(item) {
                    const url = $state.href('app.front.content-duplicate', { key: item.id, status: item.status });
                    $window.open(addFrontVersionToUrl(url), "_self");
                },
                icon: 'content-duplicate',
                label: Translation.translate('GLOBAL.COPY'),
                visibility(item) {
                    return (
                        item.status !== Config.CONTENT_STATUS.ARCHIVE.value &&
                        UserAccess.isUserAllowed('CUSTOM_CONTENT_EDIT', {
                            customContentTypeId: item.customContentType,
                        })
                    );
                },
            },
            // Delete.
            {
                action(item) {
                    vm.deleteContent(item);
                },
                icon: 'delete',
                label: Translation.translate('GLOBAL.DELETE'),
                visibility(item) {
                    return UserAccess.canDeleteContents([item]);
                },
            },
        ],
        tertiary: {
            children: [
                // Open for validation.
                {
                    action(item) {
                        $state.go('app.front.content-edit', { key: item.id });
                    },
                    label: Translation.translate('OPEN_FOR_VALIDATION'),
                    visibility(item) {
                        return (
                            UserAccess.statusActionAvailable(item, Config.CONTENT_STATUS.LIVE.value, false) &&
                            !(
                                UserAccess.isEditor(item) ||
                                UserAccess.isUserAllowed('CUSTOM_CONTENT_EDIT', {
                                    checkContent: true,
                                    content: item,
                                    customContentTypeId: item.customContentType,
                                })
                            )
                        );
                    },
                },
                // Publish.
                {
                    action(item) {
                        vm.updateContentStatus(item, Config.CONTENT_STATUS.LIVE.value);
                    },
                    label: Translation.translate(`CONTENT_SET_TO_${Config.CONTENT_STATUS.LIVE.value}`),
                    visibility(item) {
                        return (
                            (!item.lastRevision || item.status === Config.CONTENT_STATUS.DRAFT.value) &&
                            UserAccess.statusActionAvailable(item, Config.CONTENT_STATUS.LIVE.value, false)
                        );
                    },
                },
                // Unpublish.
                {
                    action(item) {
                        vm.updateContentStatus(item, Config.CONTENT_STATUS.DRAFT.value, true);
                    },
                    label: Translation.translate(`CONTENT_SET_TO_${Config.CONTENT_STATUS.DRAFT.value}`),
                    visibility(item) {
                        return (
                            UserAccess.statusActionAvailable(item, Config.CONTENT_STATUS.DRAFT.value, true) &&
                            item.status !== Config.CONTENT_STATUS.TO_VALIDATE.value
                        );
                    },
                },
                // Archive.
                {
                    action(item) {
                        vm.updateContentStatus(item, Config.CONTENT_STATUS.ARCHIVE.value);
                    },
                    label: Translation.translate(`CONTENT_SET_TO_${Config.CONTENT_STATUS.ARCHIVE.value}`),
                    visibility(item) {
                        return UserAccess.statusActionAvailable(item, Config.CONTENT_STATUS.ARCHIVE.value, true);
                    },
                },
                // Unarchive.
                {
                    action(item) {
                        vm.updateContentStatus(item, Config.CONTENT_STATUS.UNARCHIVE.value);
                    },
                    label: Translation.translate(`CONTENT_SET_TO_${Config.CONTENT_STATUS.UNARCHIVE.value}`),
                    visibility(item) {
                        return UserAccess.statusActionAvailable(item, Config.CONTENT_STATUS.UNARCHIVE.value, true);
                    },
                },
            ],
            label: Translation.translate('STATUS'),
        },
    };

    /**
     * Theactive item in the data table.
     *
     * @type {Object}
     */
    vm.activeItem = undefined;

    /**
     * The list of selected items in the data table.
     *
     * @type {Array}
     */
    vm.dataTableSelectedItems = [];

    /**
     * The sort order for the data table.
     *
     * @type {string}
     */
    vm.dataTableSortOrder = '-updatedAt';

    /**
     * Indicates if we should display current user content only or not.
     *
     * @type {boolean}
     */
    vm.isCurrentUserContent = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Config = Config;
    vm.ConfigTheme = ConfigTheme;
    vm.Content = Content;
    vm.CustomContentType = CustomContentType;
    vm.Document = Document;
    vm.Feed = Feed;
    vm.Instance = Instance;
    vm.Metadata = Metadata;
    vm.Template = Template;
    vm.Translation = Translation;
    vm.User = User;
    vm.UserAccess = UserAccess;
    vm.Utils = Utils;
    vm.dataViewId = getAttributes(METRICS_CONTEXT_NAME)({ element: 'column', action: 'views' })['data-id'];

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the default content statuses for the filter.
     * For now, everything but archived and unarchived.
     *
     * @return {Array} A list of the content statuses we pre-fill the filter with.
     */
    function _getDefaultStatuses() {
        let defaultStatuses = [Config.CONTENT_STATUS.DRAFT, Config.CONTENT_STATUS.LIVE];

        const currentCustomContentType = CustomContentType.getCurrent();

        if (currentCustomContentType.isWorkflowEnabled) {
            defaultStatuses.push(Config.CONTENT_STATUS.TO_VALIDATE);
        }

        if (vm.selectedAction === 'PAGE_EDIT') {
            defaultStatuses = without(defaultStatuses, Config.CONTENT_STATUS.TO_VALIDATE);
        } else if (vm.selectedAction === 'PAGE_PUBLISH' && currentCustomContentType.isWorkflowEnabled) {
            defaultStatuses = [Config.CONTENT_STATUS.TO_VALIDATE];
        }

        return defaultStatuses;
    }

    /**
     * Initialize the filter with the correct action.
     */
    function _initActions() {
        vm.availableActions = ['PAGE_EDIT', 'PAGE_DELETE', 'PAGE_ARCHIVE'];

        const currentCustomContentType = CustomContentType.getCurrent();

        if (currentCustomContentType.isWorkflowEnabled) {
            vm.availableActions.push('PAGE_PUBLISH');
        }

        vm.selectedAction = vm.availableActions[0];
    }

    /**
     * The function called when the update content is successfull.
     *
     * @param {Object} response The response of the update content.
     * @param {Object} content  The updated content.
     */
    function _updateContentStatusSuccessCallback(response, content) {
        if (angular.isDefined(response) && angular.isDefined(response.status)) {
            content.lastRevision = response.lastRevision;
            content.status = response.status;

            // If the new status is not part of the currently allowed statuses in the filter, remove from the list.
            if (!includes(map(vm.selectedStatuses, 'value'), content.status)) {
                const list = Content.displayList(vm.LIST_KEY);
                const itemIndex = findIndex(list, {
                    uid: content.uid,
                });
                if (itemIndex > -1) {
                    list.splice(itemIndex, 1);
                }
                vm.dataTableSelectedItems = [];
            }
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Checks if all items are selected or not.
     *
     * @return {boolean} Whether all items are selected or not.
     */
    function allSelected() {
        const contents = vm.listContent();

        return (
            angular.isDefinedAndFilled(contents) &&
            !Content.isCallInProgress(vm.LIST_KEY) &&
            contents.length === vm.dataTableSelectedItems.length
        );
    }

    /**
     * Check if the current user can create content.
     *
     * @return {boolean} Whether the current user can create a new content or not.
     */
    function canCreate() {
        return (
            !CustomContentType.is.initializing &&
            (vm.canCreateFromScratch() || Template.displayList(vm.LIST_KEY).length) &&
            UserAccess.isUserAllowed('CUSTOM_CONTENT_EDIT', {
                customContentTypeId: CustomContentType.getCurrent().uid,
            })
        );
    }

    /**
     * Check if the current user can create content from scratch.
     *
     * @return {boolean} Whether the current user can create a new content from scratch or not.
     */
    function canCreateFromScratch() {
        const currentCustomContentType = CustomContentType.getCurrent();

        return !currentCustomContentType.createFromScratchLocked || vm.UserAccess.canManageInstanceSettings();
    }

    /**
     * Close sidebar.
     */
    function closeSidebar() {
        vm.activeItem = undefined;
    }

    /**
     * Delete a content item.
     *
     * @param {Object} content The content item to be deleted.
     */
    function deleteContent(content) {
        LxNotificationService.confirm(
            Translation.translate('CONTENT_DELETE'),
            Translation.translate('CONTENT_DELETE_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            function onDeleteContentConfirmationSuccess(answer) {
                if (answer) {
                    Content.del(
                        content.id,
                        function onContentDeleteSuccess() {
                            vm.dataTableSelectedItems = [];
                            LxNotificationService.success(Translation.translate('CONTENT_DELETE_SUCCESS'));
                        },
                        Utils.displayServerError,
                        vm.LIST_KEY,
                    );
                }
            },
        );
    }

    /**
     * Delete all selected items.
     */
    function deleteSelectedItems() {
        if (vm.dataTableSelectedItems.length === 1) {
            vm.deleteContent(vm.dataTableSelectedItems[0]);
        } else {
            LxNotificationService.confirm(
                Translation.translate('ADMIN_DELETE_SELECTED_ITEMS'),
                Translation.translate('ADMIN_DELETE_SELECTED_ITEMS_DESCRIPTION'),
                {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                },
                function onDeleteSelectedItemsConfirmationSuccess(answer) {
                    if (answer) {
                        Content.delMulti(
                            map(vm.dataTableSelectedItems, 'id'),
                            function onContentDeleteMultiSuccess() {
                                vm.dataTableSelectedItems = [];
                                LxNotificationService.success(
                                    Translation.translate('ADMIN_DELETE_SELECTED_ITEMS_SUCCESS'),
                                );
                            },
                            Utils.displayServerError,
                            vm.LIST_KEY,
                        );
                    }
                },
            );
        }
    }

    /**
     * Filter content items.
     */
    function filterContent() {
        const combinedMetadata = [];

        // Clear currently selected items.
        vm.dataTableSelectedItems = [];

        angular.forEach(vm.filter.metadata, function forEachFilterMetadata(metadata) {
            if (angular.isDefinedAndFilled(metadata) && angular.isArray(metadata)) {
                combinedMetadata.push({
                    metadata,
                });
            }
        });

        const currentCustomContentType = CustomContentType.getCurrent();

        let status = map(vm.selectedStatuses, 'value');
        if (status.length === 0) {
            // If no status is selected => Request all content status.
            status = map(Object.values(Config.CONTENT_STATUS), 'value');
        }

        // Monolite/monolithe compatibility (weird stuff with the legacy api)
        // As monolite is stricter we make sure we send correctly serialized data to it
        // to avoid a fallback on monolith.
        // Note: Monolith knows how to handle both formats.
        let sortOrder = vm.dataTableSortOrder;
        if (angular.isDefinedAndFilled(sortOrder) && typeof sortOrder === 'string') {
            sortOrder = [sortOrder];
        }

        let instanceId = vm.Instance.getCurrentInstanceId();
        if (angular.isDefinedAndFilled(instanceId) && typeof instanceId === 'string') {
            instanceId = [instanceId];
        }

        Content.filterize(
            {
                action: vm.selectedAction,
                author: vm.filter.author,
                combinedMetadata,
                customContentType: currentCustomContentType.uid,
                customContentTypeTags: vm.filter.tags,
                endDateFrom: vm.filter.endDateFrom,
                endDateTo: vm.filter.endDateTo,
                excludeType: [Config.AVAILABLE_CONTENT_TYPES.CUSTOM_LIST],
                feed: vm.filter.feeds,
                query: vm.filter.title,
                sortOrder,
                status,
                instanceId,
            },
            undefined,
            Utils.displayServerError,
            vm.LIST_KEY,
            _PROJECTION,
        );
    }

    /**
     * Get content last revision field.
     *
     * @param  {Object} content The content item to check the field of.
     * @param  {string} field   The content item field id.
     * @return {string} The content item field value.
     */
    function getLastRevisionField(content, field) {
        if (
            angular.isDefinedAndFilled(content.lastRevision) &&
            angular.isDefinedAndFilled(content.lastRevision[field])
        ) {
            return content.lastRevision[field];
        }

        return content[field];
    }

    /**
     * Get sort order on given field.
     *
     * @param  {string} field The field to check the order of.
     * @return {string} The field sort order.
     */
    function getSortOrder(field) {
        if (vm.dataTableSortOrder === field) {
            return 'asc';
        } else if (vm.dataTableSortOrder === `-${field}`) {
            return 'desc';
        }

        return '';
    }

    /**
     * Check if a given content item is selected or not.
     *
     * @param  {Object}  content The content item to check the selection status of.
     * @return {boolean} Whether the content item is selected or not.
     */
    function isSelected(content) {
        return Boolean(
            loFind(vm.dataTableSelectedItems, function findInDataTableSelectedItems(item) {
                return item.id === content.id;
            }),
        );
    }

    /**
     * Filter out draft items from the displayList.
     *
     * @return {Array} A list of content items.
     */
    function listContent() {
        return vm.Content.displayList(vm.LIST_KEY).filter(function filterContentItems(content) {
            if (
                content.status.toLowerCase() === vm.Config.CONTENT_STATUS.DRAFT.value.toLowerCase() &&
                vm.selectedAction === 'PAGE_PUBLISH'
            ) {
                return false;
            }

            return true;
        });
    }

    /**
     * Open filters.
     *
     * @param {Event} evt The click event.
     */
    function openFilters(evt) {
        LxDropdownService.open(vm.DROPDOWN_ID, `#${vm.DROPDOWN_TARGET}`);

        evt.stopPropagation();
    }

    /**
     * Set active item.
     *
     * @param {Object} content The content item to set as active.
     */
    function setActiveItem(content) {
        if (angular.isDefined(vm.activeItem) && vm.activeItem.id === content.id) {
            closeSidebar();

            return;
        }

        vm.activeItem = angular.copy(content);

        if (angular.isDefinedAndFilled(vm.activeItem.feedKeys)) {
            Feed.getMulti(vm.activeItem.feedKeys).then(function onMultipleFeedsGetSuccess(feeds) {
                vm.activeItem.feeds = feeds.items;
            });
        }

        if (angular.isDefinedAndFilled(vm.activeItem.featuredFeedKeys)) {
            Feed.getMulti(vm.activeItem.featuredFeedKeys).then(function onMultipleFeaturedFeedsGetSuccess(
                featuredFeeds,
            ) {
                vm.activeItem.featuredFeeds = featuredFeeds.items;
            });
        }

        if (angular.isDefinedAndFilled(vm.activeItem.editors)) {
            Feed.getMulti(vm.activeItem.editors).then(function onMultipleEditorsGetSuccess(editorsFeeds) {
                vm.activeItem.editorsFeeds = editorsFeeds.items;
            });
        }
    }

    /**
     * Set expiration date for expired or soon to expire (30 days) content.
     */
    function setExpirationDate() {
        const momentLang = Translation.getLang('current', 'moment');
        const now = moment.utc().locale(momentLang);

        vm.filter.endDateFrom = undefined;
        vm.filter.endDateTo = undefined;

        if (vm.expired.soon && vm.expired.already) {
            vm.filter.endDateTo = now.add(1, 'months').format(FULL_DATE_SIX_FRACTIONAL_SECONDS);
        } else if (vm.expired.soon) {
            vm.filter.endDateFrom = now.format(FULL_DATE_SIX_FRACTIONAL_SECONDS);
            vm.filter.endDateTo = now.add(1, 'months').format(FULL_DATE_SIX_FRACTIONAL_SECONDS);
        } else if (vm.expired.already) {
            vm.filter.endDateTo = now.format(FULL_DATE_SIX_FRACTIONAL_SECONDS);
        }
    }

    /**
     * Fill the filter author field with the current user email.
     */
    function setFilterAuthor() {
        const userEmail = User.getConnected().email;
        if (userEmail) {
            vm.filter.author = vm.isCurrentUserContent ? userEmail : undefined;
        } else {
            vm.isCurrentUserContent = false;
        }
    }

    /**
     * Toggle all the current items in the data table.
     */
    function toggleAllItemsSelection() {
        if (vm.allSelected()) {
            vm.dataTableSelectedItems = [];
        } else {
            vm.dataTableSelectedItems = angular.copy(listContent());
        }
    }

    /**
     * Toggle selection for a particular content item from data table.
     *
     * @param {Object} content The item to toggle the selection of.
     */
    function toggleItemSelection(content) {
        if (vm.isSelected(content)) {
            for (let i = 0, len = vm.dataTableSelectedItems.length; i < len; i++) {
                if (content.id === vm.dataTableSelectedItems[i].id) {
                    vm.dataTableSelectedItems.splice(i, 1);

                    break;
                }
            }
        } else {
            vm.dataTableSelectedItems.push(content);
        }
    }

    /**
     * Toggle sort order.
     *
     * @param {string} sortOrder The sort order to be toggled.
     */
    function toggleSortOrder(sortOrder) {
        if (angular.isUndefined(vm.dataTableSortOrder) || sortOrder !== vm.dataTableSortOrder) {
            vm.dataTableSortOrder = sortOrder;
        } else {
            vm.dataTableSortOrder = `-${sortOrder}`;
        }

        vm.filterContent();
    }

    /**
     * Update the content status of a particular content item.
     *
     * @param {Object} content   The content to change the status of.
     * @param {string} newStatus The new status to change to.
     */
    function updateContentStatus(content, newStatus) {
        LxNotificationService.confirm(
            Translation.translate(`CONTENT_SET_TO_${newStatus}`),
            Translation.translate(`CONTENT_SET_TO_${newStatus}_DESCRIPTION`),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            function onUpdateContentStatusConfirmation(answer) {
                if (!answer) {
                    return;
                }

                Content.updateStatus(
                    content.id,
                    newStatus,
                    undefined,
                    function onUpdateStatus(response) {
                        _updateContentStatusSuccessCallback(response, content);
                    },
                    Utils.displayServerError,
                );
            },
        );
    }

    /////////////////////////////

    vm.allSelected = allSelected;
    vm.canCreate = canCreate;
    vm.canCreateFromScratch = canCreateFromScratch;
    vm.closeSidebar = closeSidebar;
    vm.deleteContent = deleteContent;
    vm.deleteSelectedItems = deleteSelectedItems;
    vm.filterContent = filterContent;
    vm.getLastRevisionField = getLastRevisionField;
    vm.getSortOrder = getSortOrder;
    vm.isSelected = isSelected;
    vm.listContent = listContent;
    vm.openFilters = openFilters;
    vm.setActiveItem = setActiveItem;
    vm.setExpirationDate = setExpirationDate;
    vm.setFilterAuthor = setFilterAuthor;
    vm.toggleAllItemsSelection = toggleAllItemsSelection;
    vm.toggleItemSelection = toggleItemSelection;
    vm.toggleSortOrder = toggleSortOrder;
    vm.updateContentStatus = updateContentStatus;

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any changes in the filters and refresh the list of contents.
     * Debounce the refreshing so that we don't refresh to often.
     *
     * @param {Object} newValue The new filter object.
     * @param {Object} oldValue The old filter object.
     */
    $scope.$watch(
        'vm.filter',
        function onFilterChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                filterContent();
            }
        },
        true,
    );

    /**
     * Watch active tab.
     *
     * @param {Object} newValue The new activeTab value.
     * @param {Object} oldValue The old activeTab value.
     */
    $scope.$watch(
        'vm.activeTab',
        function onActiveTabChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                vm.selectedAction = vm.availableActions[newValue];
                vm.selectedStatuses = _getDefaultStatuses();
                vm.filterContent();
            }
        },
        true,
    );

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        // Note: 'unarchive' is not a real content status on the backend - it's just for frontend purposes.
        vm.allowedStatuses = toArray(Config.CONTENT_STATUS).filter(function filterContentStatuses(contentStatus) {
            return contentStatus.value !== Config.CONTENT_STATUS.UNARCHIVE.value;
        });

        /*
         * Use a separate var (not in filter) because it has to be updated when a tab (action) is selected
         * without triggering the filter watcher.
         */
        vm.selectedStatuses = _getDefaultStatuses();

        vm.expired = {
            [vm.EXPIRATION_TYPE.ALREADY]: false,
            [vm.EXPIRATION_TYPE.SOON]: false,
        };

        vm.filter = {
            author: undefined,
            endDateFrom: undefined,
            endDateTo: undefined,
            feeds: [],
            metadata: {},
            tags: [],
            title: undefined,
        };

        // Init metadata.
        Metadata.getRefactoredMetadata().then(function onGetMetadataSuccess(refactoredMetadata) {
            angular.forEach(refactoredMetadata, function forEachMetadata(metadata) {
                vm.filter.metadata[metadata.key] = [];
            });
        });

        // Handle dynamic filter from get params.
        if ($stateParams.filters) {
            vm.filter = Utils.buildFilterFromUri($stateParams.filters, vm.filter);
        }

        const currentCustomContentType = CustomContentType.getCurrent();

        vm.LIST_KEY += currentCustomContentType.uid;

        if (currentCustomContentType.isWorkflowEnabled) {
            vm.selectedStatuses = _getDefaultStatuses();
        } else {
            vm.allowedStatuses = without(vm.allowedStatuses, Config.CONTENT_STATUS.TO_VALIDATE);
        }

        _initActions();

        vm.filterContent();

        Template.filterize(
            {
                contentType: currentCustomContentType.functionalInnerId,
                customContentType: currentCustomContentType.uid,
                visibleInheritedOnly: false,
            },
            undefined,
            undefined,
            vm.LIST_KEY,
        );
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('ContentAdminController', ContentAdminController);

/////////////////////////////

export { ContentAdminController };
