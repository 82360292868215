import loFind from 'lodash/find';
import get from 'lodash/get';
import last from 'lodash/last';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function RoleController(
    $scope,
    CustomContentType,
    Feed,
    Instance,
    LxDataTableService,
    LxDialogService,
    LxNotificationService,
    Metadata,
    NavigationPicker,
    Role,
    Translation,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The list of custom content types and siblings custom content types.
     *
     * @type {Array}
     * @readonly
     */
    let _cctList;

    /**
     * When an authorization is submitted to the role.
     *
     * @type {boolean}
     */
    let _isFormSubmitted = false;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The headers of the tables that contains the list of roles.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.DATA_TABLE_HEADERS = [
        {
            format: (row) => {
                return row.name;
            },
            label: Translation.translate('GLOBAL.NAME'),
            name: 'name',
        },
        {
            format: (row) => {
                return Translation.translate(row.description);
            },
            label: Translation.translate('GLOBAL.DESCRIPTION'),
            name: 'description',
        },
    ];

    /**
     * The id of the "roles" data table.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.DATA_TABLE_ID = 'rolesDataTable';

    /**
     * Navigation picker id, useful for the dialog.
     *
     * @type {string}
     * @readonly
     */
    vm.NAVIGATION_PICKER_ID = 'role-settings-navigation-picker';

    /**
     * The id of the role settings dialog.
     *
     * @type {string}
     */
    vm.ROLE_SETTINGS_DIALOG_ID = 'role-settings';

    /**
     * The listkey id for the feeds list.
     *
     * @type {string}
     */
    vm.ROLE_FEEDS_LISTKEY = 'role-settings-listkey';

    /**
     * The authorization object we are editing.
     *
     * @type {Object}
     */
    vm.activeAuthorization = undefined;

    /**
     * This contains the authorized feeds for each authorization (indexed by authorization id).
     *
     * @type {Object}
     */
    vm.authorizedFeeds = {};

    /**
     * The listkey id for the authorized feeds list.
     *
     * @type {string}
     */
    vm.AUTHORIZED_FEEDS_LISTKEY = 'authorized-setting-listkey';

    /**
     * Indicates if we want to focus the name field.
     * This is used for a trick for auto-focusing the field. We have to wait a slight delay before telling LumX to
     * focus it. So we will toggle this flag after a timeout.
     *
     * @type {boolean}
     */
    vm.focus = false;

    /**
     * Contains the selected roles.
     *
     * @type {Array}
     */
    vm.selectedItems = [];

    /**
     * Set to true when ready to display the feed selector.
     *
     * @type {boolean}
     */
    vm.feedsInitialized = false;

    /////////////////////////////

    vm.CustomContentType = CustomContentType;
    vm.Feed = Feed;
    vm.Metadata = Metadata;
    vm.NavigationPicker = NavigationPicker;
    vm.Role = Role;
    vm.Translation = Translation;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Filters the `Role.getList` results according to the `vm.filter` value.
     */
    function _filterList() {
        Role.filterize(vm.filter);
    }

    /**
     * Get the subscribed feeds from the list of subscribed feeds ids.
     *
     * @param {Array} [authorizations] The list of authorizations to recompute the feeds for.
     */
    function _getAuthorizationsFeeds(authorizations) {
        if (angular.isUndefined(authorizations)) {
            const currentRole = Role.getCurrent();
            if (angular.isUndefinedOrEmpty(get(currentRole, 'authorizations'))) {
                return;
            }

            authorizations = currentRole.authorizations;
        }

        if (angular.isUndefinedOrEmpty(authorizations)) {
            return;
        }

        authorizations = angular.isArray(authorizations) ? authorizations : [authorizations];

        angular.forEach(authorizations, (authorization) => {
            if (angular.isUndefinedOrEmpty(get(authorization, 'feeds'))) {
                return;
            }

            if (angular.isUndefined(authorization.uid)) {
                authorization.uid = generateUUID();
            }

            vm.authorizedFeeds[authorization.uid] = [];
            Feed.getMulti(authorization.feeds, vm.AUTHORIZED_FEEDS_LISTKEY).then((feeds) => {
                vm.authorizedFeeds[authorization.uid] = feeds.items;
            });
        });
    }

    /**
     * Update the selected roles list when a row is toggled in the data table.
     *
     * @param {Event}  evt          The select/unselect event from the data table.
     * @param {string} dataTableId  The data table identifier.
     * @param {Array}  selectedRows The selected rows.
     */
    function _updateSelectedRoles(evt, dataTableId, selectedRows) {
        if (dataTableId === vm.DATA_TABLE_ID) {
            vm.selectedItems = selectedRows;
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add a new authorization in the list of authorizations for a Role.
     * This will open the authorization management tab.
     */
    function addAuthorization() {
        vm.activeAuthorization = {
            actions: [],
            feeds: [],
            navigationUuids: [],
            uid: generateUUID(),
        };
        vm.customContentTypeSelectionEnabled = false;
    }

    /**
     * Add a new Role by opening the role settings dialog.
     */
    function addRole() {
        Role.setCurrent({
            authorizations: [],
            description: {},
            instance: Instance.getCurrentInstanceId(),
            name: undefined,
        });

        Utils.waitForAndExecute(`#${vm.ROLE_SETTINGS_DIALOG_ID}`);
    }

    /**
     * Cancel the edition of the role.
     */
    function cancelRole() {
        if (angular.isDefinedAndFilled(vm.activeAuthorization)) {
            vm.closeAuthorization();
        }

        LxDialogService.close(vm.ROLE_SETTINGS_DIALOG_ID, true);
    }

    /**
     * Check if actions are set for an authorization.
     *
     * @return {boolean} If there is actions for this authorization.
     */
    function checkActionForAuthorization() {
        return _isFormSubmitted && angular.isUndefinedOrEmpty(vm.activeAuthorization.actions);
    }

    /**
     * Close the authorization management tab.
     */
    function closeAuthorization() {
        vm.activeAuthorization = undefined;
    }

    /**
     * Delete a role by its id.
     *
     * @param {string} roleId The id of the role to remove.
     */
    function deleteRole(roleId) {
        LxNotificationService.confirm(
            Translation.translate('ADMIN_ROLE_DELETE'),
            Translation.translate('ADMIN_ROLE_DELETE_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            (answer) => {
                if (answer) {
                    Role.del(
                        roleId,
                        () => {
                            LxNotificationService.success(Translation.translate('ADMIN_ROLE_DELETE_SUCCESS'));
                        },
                        Utils.displayServerError,
                    );
                }
            },
        );
    }

    /**
     * Edit the selected authorization.
     * This will open the authorization tab with the authorization selected.
     *
     * @param {Object} authorization The authorization selected.
     */
    function editAuthorization(authorization) {
        vm.activeAuthorization = angular.fastCopy(authorization);

        // Fix https://lumapps.atlassian.net/browse/SUP-1261
        for (var i in vm.activeAuthorization.actions) {
            if (vm.activeAuthorization.actions[i].customContentType === null) {
                delete vm.activeAuthorization.actions[i].customContentType;
            }
        }
    }

    /**
     * Edit a role.
     * This will open the role settings dialog.
     *
     * @param {Object} role The selected role.
     */
    function editRole(role) {
        vm.activeAuthorization = undefined;
        Role.setCurrent(angular.fastCopy(role));

        Utils.waitForAndExecute('#role-settings');
    }

    /**
     * An action with a specific content type needs to be explicitly described.
     * This method will add the custom content type name before the action name if needed.
     * And then, it will add the action type, translated.
     *
     * @param  {string} action The action (could be for exemple: EDIT, ADD ...).
     * @return {string} The translated action with the right prefixes.
     */
    function getActionTranslation(action) {
        let actionTranslation = '';
        let contentType;

        if (angular.isDefinedAndFilled(action.customContentType)) {
            contentType = loFind(_cctList, {
                id: action.customContentType,
            });
        }

        if (angular.isDefinedAndFilled(get(contentType, 'id'))) {
            actionTranslation = Translation.translate(contentType.name);
        } else {
            actionTranslation = Translation.translate(`ACTION_TYPE_${action.name}`);
        }
        const customTranslation = action.customTranslationKey;
        actionTranslation += ` : ${Translation.translate(customTranslation ? customTranslation : `ACTION_${action.type}`)}`;

        return actionTranslation;
    }

    /**
     * Check if an Edit, Publish, Archive or Delete authorization is selected.
     *
     * @return {boolean} If an authorization is selected.
     */
    function isCustomContentAlterationChecked() {
        return loFind(vm.activeAuthorization.actions, (action) => {
            return action.type !== 'READ' && action.name === 'CUSTOM_CONTENT';
        });
    }

    /**
     * Check if the menu drop action is selected.
     *
     * @return {boolean} If the menu drop action is selected.
     */
    function isMenuDropChecked() {
        return loFind(vm.activeAuthorization.actions, {
            name: 'MENU',
            type: 'DROP',
        });
    }

    /**
     * Remove an authorization by index.
     *
     * @param {number} index Index of the authorization.
     */
    function removeAuthorization(index) {
        if (angular.isDefined(get(Role.getCurrent(), `authorizations[${index}]`))) {
            Role.getCurrent().authorizations.splice(index, 1);
        }
    }

    /**
     * Save the current active authorization for the role.
     */
    function saveAuthorization() {
        _isFormSubmitted = true;

        if (angular.isUndefinedOrEmpty(vm.activeAuthorization.actions)) {
            return;
        }

        const currentRole = Role.getCurrent();

        _isFormSubmitted = false;

        if (angular.isUndefined(currentRole.authorizations)) {
            currentRole.authorizations = [];
        }

        for (let i = 0, len = currentRole.authorizations.length; i < len; i++) {
            if (currentRole.authorizations[i].uid === vm.activeAuthorization.uid) {
                currentRole.authorizations[i] = angular.fastCopy(vm.activeAuthorization);
                vm.activeAuthorization = undefined;

                _getAuthorizationsFeeds(currentRole.authorizations[i]);

                return;
            }
        }

        currentRole.authorizations.push(angular.fastCopy(vm.activeAuthorization));

        vm.activeAuthorization = undefined;

        _getAuthorizationsFeeds(last(currentRole.authorizations));
    }

    /**
     * Save the current role and close the settings dialog.
     */
    function saveRole() {
        if (angular.isDefinedAndFilled(vm.activeAuthorization)) {
            vm.saveAuthorization();
        }

        Role.save(
            Role.getCurrent(),
            () => {
                LxNotificationService.success(Translation.translate('ADMIN_ROLE_SAVE_SUCCESS'));
                LxDialogService.close(vm.ROLE_SETTINGS_DIALOG_ID);
            },
            Utils.displayServerError,
        );
    }

    /////////////////////////////

    vm.addAuthorization = addAuthorization;
    vm.addRole = addRole;
    vm.cancelRole = cancelRole;
    vm.checkActionForAuthorization = checkActionForAuthorization;
    vm.closeAuthorization = closeAuthorization;
    vm.deleteRole = deleteRole;
    vm.editAuthorization = editAuthorization;
    vm.editRole = editRole;
    vm.getActionTranslation = getActionTranslation;
    vm.isCustomContentAlterationChecked = isCustomContentAlterationChecked;
    vm.isMenuDropChecked = isMenuDropChecked;
    vm.removeAuthorization = removeAuthorization;
    vm.saveAuthorization = saveAuthorization;
    vm.saveRole = saveRole;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the role settings dialog has opened, focus the role name field.
     *
     * @param {Event}  evt      The dialog open event.
     * @param {string} dialogId The id of the dialog that opened.
     */
    $scope.$on('lx-dialog__open-end', (evt, dialogId) => {
        if (vm.ROLE_SETTINGS_DIALOG_ID === dialogId) {
            vm.focus = true;
            vm.feedsInitialized = false;

            if (angular.isUndefinedOrEmpty(_cctList)) {
                _cctList = angular.fastCopy(
                    CustomContentType.displayList(CustomContentType.CURRENT_INSTANCE_CCT_LIST_KEY),
                );
            }

            _getAuthorizationsFeeds();

            // Prefetch feeds before displaying the feedKey selector
            if (angular.isDefinedAndFilled(Role.getCurrent().feeds)) {
                Feed.getMulti(Role.getCurrent().feeds, vm.ROLE_FEEDS_LISTKEY).then(() => {
                    vm.feedsInitialized = true;
                });
            } else {
                vm.feedsInitialized = true;
            }
        }
    });

    /**
     * When the dialog closes, reset the currently edited role.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} dialogId The identifier of the dialog triggering this method.
     */
    $scope.$on('lx-dialog__close-end', (evt, dialogId) => {
        if (vm.ROLE_SETTINGS_DIALOG_ID === dialogId) {
            LxDataTableService.unselectAll(vm.DATA_TABLE_ID);
        }
    });

    /**
     * When a row is selected in the data table, update the selected roles.
     */
    $scope.$on('lx-data-table__selected', _updateSelectedRoles);

    /**
     * When a row is deselected in the data table, update the selected roles.
     */
    $scope.$on('lx-data-table__unselected', _updateSelectedRoles);

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any changes in the filters and refresh the list of contents.
     * Debounce the refreshing so that we don't refresh to often.
     *
     * @param {Object} newValue The new filter object.
     * @param {Object} oldValue The old filter object.
     */
    $scope.$watch(
        'vm.filter',
        (newValue, oldValue) => {
            if (newValue !== oldValue) {
                _filterList();
            }
        },
        true,
    );

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        Role.filterize();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('RoleController', RoleController);

/////////////////////////////

export { RoleController };
