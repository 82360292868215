import filter from 'lodash/filter';
import loFind from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import noop from 'lodash/noop';

import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { ANALYTICS } from '@lumapps/analytics/keys';
import { getAttributeNameForGA4, getCleanedName } from '@lumapps/metric/utils';

/////////////////////////////

function ModuleUserDirectoryAdminController(
    $scope,
    $timeout,
    Config,
    ConfigTheme,
    Content,
    ContentForm,
    Customer,
    FormValidation,
    FroalaService,
    IdentityProvidersConstant,
    InitialSettings,
    Instance,
    LxDialogService,
    LxNotificationService,
    Metadata,
    ModuleAdmin,
    Translation,
    Utils,
    Features,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The list of components in the user directory.
     *
     * @type {Array}
     */
    const componentList = [];

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The id of the dialog to edit user directory.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.DIALOG_ID = ModuleAdmin.dialogKeys.userDirectory;

    /**
     * Options for the Froala WYSIWYG editor.
     *
     * @type {Object}
     */
    vm.FROALA_OPTIONS = FroalaService.getOptions(true, true, false, false, [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontFamily',
        'fontSize',
        '|',
        'color',
        'inlineStyle',
        'paragraphStyle',
        '|',
        'paragraphFormat',
        'align',
        'formatOL',
        'formatUL',
        'outdent',
        'indent',
        'insertHR',
        '|',
        'insertLink',
        'insertImage',
        'insertTable',
        'insertPopin',
        'editPopin',
        'editContent',
        'undo',
        'redo',
        'clearFormatting',
        'selectAll',
        'html',
    ]);

    /**
     * The allowed type of components.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.USER_DIRECTORY_COMPONENTS = [];

    function init() {
        vm.USER_DIRECTORY_COMPONENTS = [
            {
                component: 'inputText',
                label: 'ADMIN_MODULE_DIRECTORY_COMPONENT_SETTINGS_INPUT_TEXT',
            },
            {
                component: 'metadata',
                label: 'ADMIN_MODULE_DIRECTORY_COMPONENT_SETTINGS_METADATA',
            },
            {
                component: 'inputSelect',
                label: 'ADMIN_MODULE_DIRECTORY_COMPONENT_SETTINGS_USER_PICKER',
                name: 'userPicker',
            },
            ...(Features.hasFeature('user-profile-react') ?
                [
                    {
                        component: 'inputNumber',
                        label: 'ADMIN_MODULE_DIRECTORY_COMPONENT_SETTINGS_INPUT_NUMBER',
                    },
                    {
                        component: 'inputDate',
                        label: 'ADMIN_MODULE_DIRECTORY_COMPONENT_SETTINGS_INPUT_DATE',
                    },
                ]
                : []),
        ];
    }

    /**
     * The currently active tab in the user directory dialog.
     *
     * @type {number}
     */
    vm.activeTab = 0;

    /**
     * Errors on components forms.
     */
    vm.errors = {
        metadata: false,
    };

    /**
     * The form that contains the settings of the user directory being edited.
     *
     * @type {Object}
     */
    vm.form = {};

    /**
     * The metadata of the user directory being edited.
     *
     * @type {Array}
     */
    vm.metadata = [];

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Config = Config;
    vm.ConfigTheme = ConfigTheme;
    vm.Content = Content;
    vm.ContentForm = ContentForm;
    vm.Customer = Customer;
    vm.FormValidation = FormValidation;
    vm.Instance = Instance;
    vm.Metadata = Metadata;
    vm.ModuleAdmin = ModuleAdmin;
    vm.Translation = Translation;
    vm.analyticsKeys = ANALYTICS;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Search if a API profile field is already in use. Else put it in the free list.
     *
     * @param {Object} current The current component.
     */
    function _hideGoogleDirectoryFieldAlreadyInUse(current) {
        const currentName = current.boundMap ? current.boundMap.name : undefined;
        const availableFields = [];

        for (let fieldIdx = 0; fieldIdx < Config.AVAILABLE_API_PROFILE_FIELD.length; fieldIdx++) {
            const found = loFind(
                Content.getCurrent(ModuleAdmin.getListKey()).template.components,
                (component) =>
                    angular.isDefined(component.properties) &&
                    angular.isDefined(component.properties.boundMap) &&
                    currentName !== component.properties.boundMap.name &&
                    component.properties.boundMap.name === Config.AVAILABLE_API_PROFILE_FIELD[fieldIdx].name,
            );

            if (!found) {
                availableFields.push(Config.AVAILABLE_API_PROFILE_FIELD[fieldIdx]);
            }
        }

        availableFields.push({ name: 'API_PROFILE_FIELD_CUSTOM', text: '' });

        vm.freeApiProfileField = availableFields;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add a component to the user directory.
     */
    function addUserDirectoryField() {
        vm.activeUserComponent = {
            availableValues: [{}],
            uuid: generateUUID(),
        };

        vm.computeMetadataList();

        vm.isEdit = false;

        _hideGoogleDirectoryFieldAlreadyInUse(vm.activeUserComponent);
    }

    /**
     * Check the API profile path of the component.
     *
     * @return {boolean} If the component has a API profile path.
     */
    function checkComponentApiProfileField() {
        const isGoogleDir = angular.isDefined(vm.activeUserComponent.isBound) && vm.activeUserComponent.isBound;

        return (
            vm.form.moduleUserDirectoryTemplate.$submitted &&
            isGoogleDir &&
            angular.isUndefined(vm.activeUserComponent.boundMap)
        );
    }

    /**
     * Check if the component has a feed.
     *
     * @return {boolean} If the component has a feed.
     */
    function checkComponentFeed() {
        return (
            vm.form.moduleUserDirectoryTemplate.$submitted &&
            (angular.isUndefined(vm.activeUserComponent.seeFeeds) || vm.activeUserComponent.seeFeeds.length === 0)
        );
    }

    /**
     * Check if the component has a type.
     *
     * @return {boolean} If the component has a type.
     */
    function checkComponentType() {
        return vm.form.moduleUserDirectoryTemplate.$submitted && angular.isUndefined(vm.activeUserComponent.type);
    }

    /**
     * Return the component UUID.
     *
     * @param {Object}   component The component to get the UUID of the component from.
     * @param {Function} [cb]      The callback to call with the UUID.
     */
    function componentToUuid(component, cb) {
        cb = cb || noop;

        cb(get(component, 'uuid'));
    }

    /**
     * Reset the current active component.
     */
    function closeUserDirectoryField() {
        vm.activeUserComponent = undefined;
        vm.freeApiProfileField = [];
    }

    /**
     * Delete a component.
     * Before deleting it, ask the user if he is sure.
     *
     * @param {Object} component The component to delete.
     */
    function deleteUserDirectoryField(component) {
        LxNotificationService.confirm(
            Translation.translate('ADMIN_MODULE_DIRECTORY_COMPONENT_DELETE'),
            Translation.translate('ADMIN_MODULE_DIRECTORY_COMPONENT_DELETE_DESCRIPTION'),
            { cancel: Translation.translate('CANCEL'), ok: Translation.translate('OK') },
            (answer) => {
                if (answer) {
                    vm.removeUserDirectoryField(component);
                }
            },
        );
    }

    /**
     * Return a filtered component list.
     *
     * @return {Array} The list of filtered components.
     */
    function displayComponentList() {
        return Utils.arrayRefresh(
            componentList,
            filter(
                Content.getCurrent(ModuleAdmin.getListKey()).template.components,
                (component) => component.type !== 'widget',
            ),
        );
    }

    /**
     * Edit a component (set it as active).
     *
     * @param {Object} component The component to edit
     * @param {number} index     The index of the component to edit.
     */
    function editUserDirectoryField(component, index) {
        const activeComponentType = loFind(vm.USER_DIRECTORY_COMPONENTS, { component: component.type });

        if (angular.isUndefined(component.properties)) {
            component.properties = {};
        }

        vm.activeUserComponent = angular.fastCopy({
            analyticsCode: component.properties.analyticsCode,
            availableValues: component.properties.availableValues,
            boundMap: component.properties.boundMap,
            defaultParam: component.properties.defaultParam,
            displayInFilter: component.properties.displayInFilter,
            displayInList: component.properties.displayInList,
            editFeeds: component.properties.editFeeds,
            fullWidth: component.properties.fullWidth,
            icon: component.properties.icon,
            index,
            isBound: component.properties.isBound,
            metadataId: component.properties.metadataId,
            multiple: component.properties.multiple,
            seeFeeds: component.properties.seeFeeds,
            selfEdit: Boolean(
                (Content.getCurrent(ModuleAdmin.getListKey()).properties &&
                    Content.getCurrent(ModuleAdmin.getListKey()).properties.allowProfileUpdate &&
                    Boolean(component.properties.selfEdit)) ||
                    component.properties.selfEdit,
            ),
            textarea: component.properties.textarea,
            title: component.title,
            type: activeComponentType,
            uuid: component.uuid,
            validationRegex: component.properties.validationRegex,
            validationRegexErrorLabel: component.properties.validationRegexErrorLabel,
        });

        vm.isEdit = true;

        vm.computeMetadataList();

        _hideGoogleDirectoryFieldAlreadyInUse(vm.activeUserComponent);

        vm.ga4CodePreview = getAttributeNameForGA4(`lumapps_user_dir_${vm.activeUserComponent.analyticsCode}`)
    }

    /**
     * Filter the component list with all the component of the same type of the given one.
     *
     * @param  {Object} component The component we want to use the type of to filter the list.
     * @return {Array}  The filtered list.
     */
    function filterComponent(component) {
        return loFind(vm.USER_DIRECTORY_COMPONENTS, (item) => item.component === component.type);
    }

    /**
     * Compute the list of metadata that can be selected for the user directory component.
     *
     * @return {Array} The metadata list.
     */
    function computeMetadataList() {
        vm.metadata = angular.fastCopy(Metadata.getRefactoredMetadata(true)) || [];

        return vm.metadata;
    }

    /**
     * Remove the component from the component list.
     *
     * @param {Object} component The component to remove.
     */
    function removeUserDirectoryField(component) {
        Content.getCurrent(ModuleAdmin.getListKey()).template.components.splice(
            Content.getCurrent(ModuleAdmin.getListKey()).template.components.indexOf(component),
            1,
        );

        closeUserDirectoryField();
    }

    /**
     * Save the user directory.
     */
    function save() {
        // Save the component in edition.
        if (angular.isDefined(vm.activeUserComponent)) {
            if (!vm.saveUserDirectoryField()) {
                return;
            }
        }

        ModuleAdmin.addTip();

        ContentForm.saveContent(
            (response) => {
                const idx = findIndex(InitialSettings.USER_DIRECTORIES, (item) => item.id === response.id);

                if (angular.isNumber(idx) && idx >= 0) {
                    // Update.
                    InitialSettings.USER_DIRECTORIES[idx] = response;
                } else {
                    // Add.
                    InitialSettings.USER_DIRECTORIES.push(response);
                }

                LxNotificationService.success(Translation.translate('ADMIN_MODULE_SAVE_SUCCESS'));
                LxDialogService.close(vm.DIALOG_ID);
            },
            (err) => {
                vm.activeTab = 0;

                $timeout(() => {
                    if (angular.isDefined(vm.form.moduleUserDirectory)) {
                        FormValidation.setFormDirty(vm.form.moduleUserDirectory);
                    }
                });
                Utils.displayServerError(err);
            },
            ModuleAdmin.getListKey(),
        );
    }

    /**
     * Check if the RegExp pattern is good.
     *
     * @param  {string}  pattern The RegExp pattern to check.
     * @return {boolean} If the RegExp pattern is valid or not.
     */
    function isRegexValid(pattern) {
        const parts = pattern.split('/');
        let regex = pattern;
        let options = '';

        if (parts.length > 1) {
            [, regex, options] = parts;
        }

        try {
            // eslint-disable-next-line no-new
            new RegExp(regex, options);

            return true;
        } catch (exception) {
            return false;
        }
    }

    /**
     * Set or unset default RegExp.
     */
    function setDefaultRegex() {
        const { pattern } = vm.activeUserComponent.defaultParam;

        if (angular.isDefinedAndFilled(pattern)) {
            vm.activeUserComponent.validationRegex = pattern;
        } else {
            vm.activeUserComponent.validationRegex = undefined;
        }
    }

    /**
     * Transform an UUID to a full component..
     * This is mainly used by LumX lxSelect "modelToSelection".
     *
     * @param {string}   uuid The UUID of the component we want to get.
     * @param {Function} [cb] The lxSelect callback function.
     */
    function uuidToComponent(uuid, cb) {
        cb = cb || noop;

        cb(
            loFind(
                Content.getCurrent(ModuleAdmin.getListKey()).template.components,
                (component) => component.uuid === uuid,
            ),
        );
    }

    /**
     * Put component to the component list of the content.
     *
     * @return {boolean} If the component has been put.
     */
    function saveUserDirectoryField() {
        const isGoogleDir = angular.isDefined(vm.activeUserComponent.isBound) && vm.activeUserComponent.isBound;
        let err = false;

        if (
            angular.isDefined(vm.activeUserComponent.validationRegex) &&
            !isRegexValid(vm.activeUserComponent.validationRegex)
        ) {
            LxNotificationService.error(Translation.translate('ERROR_INVALID_REGEX'));

            return false;
        }
        if (angular.isDefined(vm.form.moduleUserDirectoryTemplate) && vm.form.moduleUserDirectoryTemplate.$invalid) {
            err = true;
        } else if (angular.isUndefined(vm.activeUserComponent.type)) {
            err = true;
        } else if (isGoogleDir && angular.isUndefined(vm.activeUserComponent.boundMap)) {
            err = true;
        } else if (
            angular.isUndefined(vm.activeUserComponent.seeFeeds) ||
            vm.activeUserComponent.seeFeeds.length === 0
        ) {
            err = true;
        } else if (
            vm.activeUserComponent.type.component === 'metadata' &&
            angular.isUndefinedOrEmpty(vm.activeUserComponent.metadataId)
        ) {
            err = true;
            vm.errors.metadata = true;
        }

        if (err) {
            if (angular.isDefined(vm.form.moduleUserDirectoryTemplate)) {
                FormValidation.setFormDirty(vm.form.moduleUserDirectoryTemplate);
            }

            LxNotificationService.error(Translation.translate('ERROR_CHECK_FORM'));

            return false;
        }

        vm.errors.metadata = false;

        const componentToSave = {
            properties: {
                analyticsCode: vm.activeUserComponent.analyticsCode,
                availableValues:
                    angular.isDefined(vm.activeUserComponent.availableValues) &&
                    vm.activeUserComponent.availableValues.length > 0
                        ? vm.activeUserComponent.availableValues
                        : undefined,
                boundMap: vm.activeUserComponent.isBound ? vm.activeUserComponent.boundMap : undefined,
                defaultParam: vm.activeUserComponent.defaultParam,
                displayInFilter: vm.activeUserComponent.displayInFilter,
                displayInList: vm.activeUserComponent.displayInList,
                editFeeds: vm.activeUserComponent.editFeeds,
                fullWidth: vm.activeUserComponent.fullWidth,
                icon: vm.activeUserComponent.icon,
                isBound: vm.activeUserComponent.isBound,
                metadataId: vm.activeUserComponent.metadataId,
                multiple: vm.activeUserComponent.multiple,
                secondaryType: vm.activeUserComponent.type.name,
                seeFeeds: vm.activeUserComponent.seeFeeds,
                selfEdit: vm.activeUserComponent.selfEdit,
                textarea: vm.activeUserComponent.textarea,
                validationRegex: vm.activeUserComponent.validationRegex,
                validationRegexErrorLabel: vm.activeUserComponent.validationRegexErrorLabel,
            },
            title: vm.activeUserComponent.title,
            type: vm.activeUserComponent.type.component,
            uuid: vm.activeUserComponent.uuid,
            value: {},
        };

        if (angular.isUndefined(Content.getCurrent(ModuleAdmin.getListKey()).template)) {
            Content.getCurrent(ModuleAdmin.getListKey()).template = {};
        }

        if (angular.isUndefined(Content.getCurrent(ModuleAdmin.getListKey()).template.components)) {
            Content.getCurrent(ModuleAdmin.getListKey()).template.components = [];
        }

        if (angular.isUndefined(vm.activeUserComponent.index)) {
            Content.getCurrent(ModuleAdmin.getListKey()).template.components.push(componentToSave);
        } else {
            for (let idx = 0; idx < Content.getCurrent(ModuleAdmin.getListKey()).template.components.length; idx++) {
                if (
                    componentToSave.uuid === Content.getCurrent(ModuleAdmin.getListKey()).template.components[idx].uuid
                ) {
                    angular.copy(
                        componentToSave,
                        Content.getCurrent(ModuleAdmin.getListKey()).template.components[idx],
                    );

                    break;
                }
            }
        }

        vm.activeUserComponent = undefined;

        return true;
    }

    /**
     * Slugify the Analytics code.
     */

    function slugifyAnalyticsCode() {
        const cleanedCode = getCleanedName(vm.activeUserComponent.analyticsCode);

        vm.activeUserComponent.analyticsCode = cleanedCode;
        vm.ga4CodePreview = getAttributeNameForGA4(`lumapps_user_dir_${cleanedCode}`)
    }

    /////////////////////////////
    vm.ga4CodePreview = '';
    vm.addUserDirectoryField = addUserDirectoryField;
    vm.checkComponentApiProfileField = checkComponentApiProfileField;
    vm.checkComponentFeed = checkComponentFeed;
    vm.checkComponentType = checkComponentType;
    vm.closeUserDirectoryField = closeUserDirectoryField;
    vm.componentToUuid = componentToUuid;
    vm.computeMetadataList = computeMetadataList;
    vm.deleteUserDirectoryField = deleteUserDirectoryField;
    vm.displayComponentList = displayComponentList;
    vm.editUserDirectoryField = editUserDirectoryField;
    vm.filterComponent = filterComponent;
    vm.removeUserDirectoryField = removeUserDirectoryField;
    vm.save = save;
    vm.saveUserDirectoryField = saveUserDirectoryField;
    vm.setDefaultRegex = setDefaultRegex;
    vm.slugifyAnalyticsCode = slugifyAnalyticsCode;
    vm.uuidToComponent = uuidToComponent;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    // Watch input language lang change to reset from pristine.

    /**
     * When the input language changes, reset the form to its pristine state.
     */
    $scope.$on('inputLanguage', () => {
        if (angular.isDefined(vm.form.moduleUserDirectory)) {
            vm.form.moduleUserDirectory.$setPristine();
        }

        if (angular.isDefined(vm.form.moduleUserDirectoryTemplate)) {
            vm.form.moduleUserDirectoryTemplate.$setPristine();
        }
    });

    /**
     * When the main dialog is canceled while a field was being edited (which is now possible), close the field.
     *
     * @param {Event}   evt      The close event.
     * @param {string}  dialogId The id of the dialog being closed.
     * @param {boolean} canceled Indicates if the dialog has been canceled.
     */
    $scope.$on('lx-dialog__close-end', function onDialogClose(evt, dialogId, canceled) {
        if (vm.DIALOG_ID === dialogId) {
            vm.activeTab = 0;

            if (angular.isDefinedAndFilled(vm.activeUserComponent) && canceled) {
                vm.closeUserDirectoryField();
            }
        }
    });

    init();
}

/////////////////////////////

angular.module('Controllers').controller('ModuleUserDirectoryAdminController', ModuleUserDirectoryAdminController);

/////////////////////////////

export { ModuleUserDirectoryAdminController };
