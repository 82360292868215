import { createStructuredContent, updateStructuredContent } from '@lumapps/structured-content/api';
import { TranslateObject, toApiV2Language } from '@lumapps/translations';
import { LegacyWidget } from '@lumapps/widget-base/types';
import { setTitle } from '@lumapps/wrex/serialization/dita/utils/setTitle';

import { LegacyWidgetContributionProps } from '../../type';

// This function saves a structured content from a contribution widget
export const saveStructuredContentFromWidget = async (
    contributionWidget: LegacyWidget<LegacyWidgetContributionProps>,
    title: TranslateObject<string>,
) => {
    const widgetProperties = contributionWidget.properties;
    const dita = widgetProperties?.dita;

    if (dita) {
        const newDita = Object.keys(dita).reduce((acc, cur) => {
            return {
                ...acc,
                [toApiV2Language(cur)]: setTitle(dita[cur], title[cur]),
            };
        }, {});

        if (contributionWidget.properties?.structuredContentId) {
            const { id, version } = await updateStructuredContent(
                contributionWidget.properties?.structuredContentId,
                newDita,
            );

            Object.assign(contributionWidget.properties, {
                structuredContentId: id,
                structuredContentVersion: version,
            });
        } else {
            const { id, version } = await createStructuredContent(newDita);

            Object.assign(widgetProperties, {
                structuredContentId: id,
                structuredContentVersion: version,
            });
        }
    } else {
        throw new Error();
    }
};
