/* istanbul ignore file */

import BaseApi from '@lumapps/base-api';

import { StructuredContent } from '../types';

/**
 * Structured content API.
 *
 * @see https://github.com/lumapps/core/blob/master/specs/contribution_api_v1.yaml
 */
export const structuredContentApi = new BaseApi({ version: BaseApi.versions.v2, path: 'structured-content' });

/** Get structured content by ID. */
export const getStructuredContentById = async (structuredContentId: string) => {
    const { data } = await structuredContentApi.get<StructuredContent>(structuredContentId);
    return data;
};

/** Update a structured content. */
export const updateStructuredContent = async (
    structuredContentId: string,
    dita: StructuredContent['dita']['translations'],
) => {
    const { data } = await structuredContentApi.put<StructuredContent>(structuredContentId, { translations: dita });
    return data;
};

/** Update a structured content. */
export const createStructuredContent = async (translatedDitas: StructuredContent['dita']['translations']) => {
    const { data } = await structuredContentApi.post<StructuredContent>('', { translations: translatedDitas });
    return data;
};
