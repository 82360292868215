import loFind from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import set from 'lodash/set';
import { angularApi } from '@lumapps/router/routers';
import { adminSettings } from '@lumapps/instance-settings/routes';

/////////////////////////////

function InstanceStyleSidebarController(
    $compile,
    $q,
    $rootScope,
    $scope,
    $timeout,
    $state,
    Customer,
    Features,
    FormValidation,
    Header,
    Instance,
    LxDialogService,
    LxNotificationService,
    Metadata,
    Style,
    Translation,
    User,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * A DOM element that darkens the rest of the page when the loader is displayed.
     *
     * @type {Element}
     */
    let _appFilter;

    /**
     * The original Style object. Stored on init to easily rollback when cancelling / navigating away.
     *
     * @type {Object}
     */
    let _originalStyle;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains all the font weight allowed to be used in the styles.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.AVAILABLE_FONT_WEIGHTS = ['light', 'normal', 'semibold', 'bold'];

    /**
     * The footer language selector identifier.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.FOOTER_LANGUAGE_IDENTIFIER = 'footer-settings-input-language';

    /**
     * The list key for the global styles.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.LIST_KEY = 'global';

    /**
     * Code mirror options for CSS edition.
     *
     * @type {Object}
     */
    vm.cmCssOptions = {
        lineNumbers: true,
        mode: 'css',
        smartIndent: true,
    };

    /**
     * Code mirror options for HTML edition.
     *
     * @type {Object}
     */
    vm.cmHtmlOptions = {
        lineNumbers: true,
        mode: 'html',
        smartIndent: true,
    };

    /**
     * The name of the instance style dialog.
     *
     * @type {string}
     */
    vm.dialogId = 'instance-style-dialog';

    /**
     * The instance style form.
     *
     * @type {Object}
     */
    vm.form = {};

    /**
     * Indicates if the styles are beeing saved.
     *
     * @type {boolean}
     */
    vm.isLoading = false;


    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Customer = Customer;
    vm.Features = Features;
    vm.FormValidation = FormValidation;
    vm.Instance = Instance;
    vm.Style = Style;
    vm.Translation = Translation;
    vm.User = User;
    vm.Utils = Utils;
    vm.hasCodeMirrorLoaded = false;
    vm.unsavedAdvancedStylingModifications = false;

    function updateStyleId() {
        const style = Style.getCurrent(vm.LIST_KEY);
        vm.styleId = style.uid;
        vm.footer = style.properties.footer;
        vm.unsavedAdvancedStylingModifications = false;
    }

    function updateFooter(footer) {
        const style = Style.getCurrent(vm.LIST_KEY);
        const newStyle = angular.fastCopy(style);

        Object.assign(newStyle.properties, {
            footer,
        });

        Style.setCurrent(newStyle, vm.LIST_KEY);

        vm.footer = footer;
    }

    function onCodeMirrorLoad() {
        vm.hasCodeMirrorLoaded = true;
    }

    function onAdvancedStylingSave(updatedStyles = {}) {
        const { cssRoot, cssCustom, instanceHead } = updatedStyles;
        const style = Style.getCurrent(vm.LIST_KEY);
        let unsavedAdvancedStylingModifications = false;
        const canEditRootStyles = vm.User.isGod() || vm.User.isDesigner();

        const newStylesheets = style.stylesheets.map((stylesheet) => {
            const newStylesheet = angular.fastCopy(stylesheet);

            if (newStylesheet.kind === 'root' && canEditRootStyles) {
                Object.assign(newStylesheet, {
                    content: cssRoot,
                });

                if (stylesheet.content !== cssRoot) {
                    unsavedAdvancedStylingModifications = true;
                }
            } else if (newStylesheet.kind === 'custom') {
                Object.assign(newStylesheet, {
                    content: cssCustom,
                });

                if (stylesheet.content !== cssCustom) {
                    unsavedAdvancedStylingModifications = true;
                }
            }

            return newStylesheet;
        });

        const newStyle = angular.fastCopy(style);
        Object.assign(newStyle, {
            stylesheets: newStylesheets,
        });

        Style.setCurrent(newStyle, vm.LIST_KEY);

        const currentInstance = Instance.getInstance();

        if (currentInstance.head !== instanceHead) {
            unsavedAdvancedStylingModifications = true;
        }

        vm.updatedHead = instanceHead;
        vm.unsavedAdvancedStylingModifications = unsavedAdvancedStylingModifications;

        $rootScope.$digest();
    }
    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Hide the loader.
     */
    function _hideLoader() {
        $timeout(function delayLoaderRemove() {
            if (_appFilter) {
                _appFilter.remove();
            }
        }, 100);
    }

    function backToContent() {
        angularApi.redirect(adminSettings());
    }

    vm.backToContent = backToContent;

    /**
     * Inject stylesheets to the HTML head section for preview.
     */
    function _injectStylesheets() {
        vm.rootStylesheet = undefined;
        vm.customStylesheet = undefined;

        angular.element('#instance-stylesheet-root').remove();
        angular.element('#instance-stylesheet-custom').remove();

        const currentInstance = Instance.getInstance();
        const currentStyle = Style.getCurrent(vm.LIST_KEY);

        if (angular.isArray(currentStyle.stylesheets)) {
            angular.forEach(currentStyle.stylesheets, function forEachStylesheets(stylesheet) {
                vm[`${stylesheet.kind}Stylesheet`] = stylesheet;
            });
        } else {
            currentStyle.stylesheets = [];
        }

        if (angular.isUndefinedOrEmpty(vm.rootStylesheet)) {
            vm.rootStylesheet = {
                content: undefined,
                kind: 'root',
            };

            currentStyle.stylesheets.push(vm.rootStylesheet);
        } else if (!Utils.isSafeModeEnabled() && angular.isDefinedAndFilled(vm.rootStylesheet.url)) {
            angular
                .element('head')
                .append(`<link id="instance-stylesheet-root" rel="stylesheet" href="${vm.rootStylesheet.url}">`);
        }

        if (angular.isUndefinedOrEmpty(vm.customStylesheet)) {
            vm.customStylesheet = {
                content: get(currentInstance, 'customStylesheet.content', ''),
                kind: 'custom',
            };

            currentStyle.stylesheets.push(vm.customStylesheet);
        } else if (!Utils.isSafeModeEnabled()) {
            angular
                .element('head')
                .append(`<link id="instance-stylesheet-custom" rel="stylesheet" href="${vm.customStylesheet.url}">`);
        }
    }

    /**
     * Load the content of the stylesheet from the endpoint.
     */
    function _loadStylesheet() {
        const currentStyle = Style.getCurrent(vm.LIST_KEY);

        Style.get(currentStyle.uid, function onStyleGetSuccess(res) {
            currentStyle.stylesheets = res.stylesheets;
            _injectStylesheets();
        });
    }

    /**
     * Initialize default styles value.
     */
    function _initDefaultValues() {
        const currentStyle = Style.getCurrent(vm.LIST_KEY);

        set(currentStyle, 'properties.top.theme', get(currentStyle, 'properties.top.theme', 'light'));
        set(currentStyle, 'properties.top.position', get(currentStyle, 'properties.top.position', 'content'));

        if (Style.isCustomer(currentStyle, vm.LIST_KEY)) {
            vm.tmpCustomerGlobalStyle = currentStyle;
        }

        _loadStylesheet();
    }

    /**
     * Reset the global styles.
     */
    function _resetGlobalStyle() {
        Style.setCurrent(angular.fastCopy(Style.getEmptyGlobalStyle()), vm.LIST_KEY);

        _initDefaultValues();

        updateStyleId();
    }

    /**
     * Resets the current style to its original value. It will ignore changes that have not been saved.
     */
    function _resetStyle() {
        Style.setCurrent(_originalStyle, vm.LIST_KEY);

        updateStyleId();
    }

    /**
     * Show the loader.
     */
    function _showLoader() {
        const loader = $compile('<lx-progress lx-variant="circular" lx-theme="dark"></lx-progress>')($scope);

        _appFilter = angular.element('<div/>', {
            class: 'app-filter app-filter--designer',
        });

        _appFilter.append(loader).appendTo('body');
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Manage style.
     */
    function manageStyle() {
        let currentStyle = Style.getCurrent(vm.LIST_KEY);

        if (vm.styleContext === 'instance') {
            Style.setCurrent(angular.fastCopy(currentStyle));
            currentStyle = Style.getCurrent(vm.LIST_KEY);

            const instanceGlobalStyle = Style.getInstanceGlobalStyle();
            // Save current instance style.
            if (angular.isDefinedAndFilled(get(instanceGlobalStyle, 'instance'))) {
                if (
                    angular.isUndefinedOrEmpty(
                        [currentStyle.name, currentStyle.id, currentStyle.uuid, currentStyle.uid],
                        'some',
                    )
                ) {
                    angular.extend(currentStyle, Style.getInstanceGlobalStyle());
                    Style.setCurrent(angular.fastCopy(currentStyle));
                }

                vm.save(true);
                // Add new instance style.
            } else {
                Utils.waitForAndExecute(`#${vm.dialogId}`);
            }
        } else if (vm.styleContext === 'customer') {
            // The user is the customer admin.
            if (User.getConnected().isSuperAdmin) {
                // Add new customer style.
                if (
                    angular.isUndefinedOrEmpty(Style.displayList('globalCustomer')) ||
                    angular.isUndefined(vm.tmpCustomerGlobalStyle) ||
                    angular.isUndefinedOrEmpty(vm.tmpCustomerGlobalStyle.name)
                ) {
                    Style.setCurrent(angular.fastCopy(currentStyle));
                    currentStyle = Style.getCurrent(vm.LIST_KEY);
                    Style.getCurrent().properties.isCustomer = true;

                    Utils.waitForAndExecute(`#${vm.dialogId}`);
                    // Edit current customer style.
                } else if (angular.isDefinedAndFilled(vm.tmpCustomerGlobalStyle)) {
                    Style.setCurrent(angular.fastCopy(currentStyle));
                    currentStyle = Style.getCurrent(vm.LIST_KEY);

                    vm.save(true);
                }
                // Admin.
            } else if (angular.isUndefinedOrEmpty(vm.tmpCustomerGlobalStyle)) {
                LxNotificationService.error(Translation.translate('INSTANCE_STYLE_CUSTOMER_STYLE_MANDATORY'));
            } else {
                Style.setCurrent(angular.fastCopy(currentStyle));
                currentStyle = Style.getCurrent(vm.LIST_KEY);

                vm.save(true);
            }
        } else {
            Style.setCurrent(angular.fastCopy(currentStyle));
            currentStyle = Style.getCurrent(vm.LIST_KEY);

            Utils.waitForAndExecute(`#${vm.dialogId}`);
        }
    }
    /**
     * Save instance styles.
     *
     * @param  {boolean} bypass Indicates if we want to bypass the validity check of the form.
     * @return {Promise} The promise of the save.
     */
    function save(bypass) {
        if ((angular.isUndefinedOrEmpty(vm.form.instanceStyle) || !vm.form.instanceStyle.$valid) && !bypass) {
            FormValidation.setFormDirty(vm.form.instanceStyle);

            const err = Translation.translate('ERROR_CHECK_FORM');
            LxNotificationService.error(err);

            return $q(function resolver(resolve, reject) {
                reject(err);
            });
        }

        const deferred = $q.defer();
        vm.isLoading = true;

        if (bypass) {
            _showLoader();
        }

        const status = {
            slideshow: false,
            style: false,
        };

        const currentInstance = Instance.getInstance();
        Metadata.formatMetadataForServer(currentInstance);

        // Slideshow.
        const currentHeader = Header.getCurrent();
        currentHeader.media = map(currentHeader.properties.media, 'id');
        currentHeader.instance = currentInstance.id;

        /**
         * We need to check if whether the updated head is different from null since
         * it could happen that all of the code is removed and that vm.updatedHead === "".
         * If we do an if (vm.updatedHead) and that vm.updatedHead === "" the code update
         * would not work since if (vm.updatedHead) === false.
         */
        if (vm.updatedHead !== null) {
            currentInstance.head = vm.updatedHead;
        }

        Header.save(
            currentHeader,
            function onHeaderSaveSuccess(response) {
                Header.setDefaultHeader(response);

                if (angular.isUndefinedOrEmpty(currentInstance.defaultHeader)) {
                    currentInstance.defaultHeader = response.id;

                    Instance.save(
                        Instance.formatObjectForClient(angular.copy(currentInstance)),
                        function onInstanceSaveSuccess() {
                            deferred.notify('slideshow');
                        },
                        function onInstanceSaveError(err) {
                            status.slideshow = 'error';
                            deferred.reject(err);
                        },
                    );
                } else {
                    deferred.notify('slideshow');
                }
            },
            function onHeaderSaveError(err) {
                status.slideshow = 'error';
                deferred.reject(err);
            },
        );

        // Style.
        let currentStyle = Style.getCurrent();
        if (!currentStyle.properties.isCustomer) {
            currentStyle.instance = currentInstance.id;
        }

        if (User.getConnected().isSuperAdmin || Style.isInstance(currentStyle, vm.LIST_KEY)) {
            Style.save(
                currentStyle,
                function onStyleSaveSuccess(response) {
                    response.properties.top = response.properties.top || {};
                    response.properties.mainNav = response.properties.mainNav || {};
                    response.properties.search = response.properties.search || {};

                    if (Style.isCustomer(response, vm.LIST_KEY)) {
                        vm.tmpCustomerGlobalStyle = response;

                        const foundStyle = loFind(Style.displayList('globalCustomer'), {
                            id: response.id,
                        });
                        if (angular.isUndefinedOrEmpty(foundStyle)) {
                            Style.displayList('globalCustomer').push(response);
                        }
                    }

                    _originalStyle = angular.fastCopy(response);

                    Style.setCurrent(response, vm.LIST_KEY);
                    currentStyle = Style.getCurrent();

                    if (angular.isUndefinedOrEmpty(vm.styleContext)) {
                        vm.styleContext = Style.isInstance(Style.getCurrent(vm.LIST_KEY), vm.LIST_KEY)
                            ? 'instance'
                            : 'customer';
                    }

                    currentInstance.style = response.id;

                    Instance.save(
                        Instance.formatObjectForClient(angular.copy(currentInstance)),
                        function onSaveInstanceSuccess() {
                            deferred.notify('style');
                        },
                        function onSaveInstanceError(err) {
                            status.style = 'error';
                            deferred.reject(err);
                        },
                    );
                },
                function onStyleSaveError(err) {
                    status.style = 'error';
                    deferred.reject(err);
                },
                vm.LIST_KEY,
            );
        } else {
            currentInstance.style = Style.getCurrent(vm.LIST_KEY).id;

            Instance.save(
                Instance.formatObjectForClient(angular.fastCopy(currentInstance)),
                function onSaveInstanceSuccess() {
                    deferred.notify('style');
                },
                function onSaveInstanceError(err) {
                    status.style = 'error';
                    deferred.reject(err);
                },
            );
        }

        // Promise.
        return deferred.promise
            .then(undefined, Utils.displayServerError, function onPromiseNotify(value) {
                let allDone = true;

                if (angular.isDefined(status[value])) {
                    status[value] = true;
                }

                angular.forEach(status, function forEachStatuses(elementStatus) {
                    if (elementStatus === false) {
                        allDone = false;
                    }
                });

                if (allDone) {
                    deferred.resolve();

                    localStorage.removeItem('serviceInitCache');

                    LxNotificationService.success(Translation.translate('INSTANCE_STYLE_SAVE_SUCCESS'));
                }

                vm.unsavedAdvancedStylingModifications = false;
            })
            .finally(function onPromiseFinally() {
                vm.isLoading = false;
                _hideLoader();

                LxDialogService.close(vm.dialogId);

                window.location.reload();
            });
    }

    /**
     * Set current global style.
     */
    function setCurrentGlobalStyle() {
        if (angular.isUndefined(vm.tmpCustomerGlobalStyle)) {
            _resetGlobalStyle();
        } else {
            Style.get(vm.tmpCustomerGlobalStyle.uid, function onStyleGetSuccess(res) {
                Style.setCurrent(res, vm.LIST_KEY);        
            });      
        }

        updateStyleId();
    }

    /**
     * Switch style context.
     */
    function switchStyleContext() {
        if (vm.styleContext === 'customer') {
            const currentInstance = Instance.getInstance();

            if (
                angular.isDefinedAndFilled(currentInstance.style) &&
                Style.isCustomer(currentInstance.style, vm.LIST_KEY)
            ) {
                vm.tmpCustomerGlobalStyle = Style.getCurrent(vm.LIST_KEY);
                _loadStylesheet();
            } else {
                _resetGlobalStyle();
            }
        } else if (vm.styleContext === 'instance') {
            // Get the style specific to the current.
            const instanceGlobalStyle = Style.getInstanceDefaultStyle();

            if (angular.isUndefinedOrEmpty(instanceGlobalStyle)) {
                _resetGlobalStyle();

                return;
            }

            Style.setCurrent(instanceGlobalStyle, vm.LIST_KEY);
            _loadStylesheet();
        }

        updateStyleId();
    }

    /**
     * Update main navigation on settings change.
     */
    function updateInstanceStyle() {
        $timeout(() => {
            $rootScope.$broadcast('instance-style-settings');
        });
    }

    /////////////////////////////

    vm.manageStyle = manageStyle;
    vm.save = save;
    vm.setCurrentGlobalStyle = setCurrentGlobalStyle;
    vm.switchStyleContext = switchStyleContext;
    vm.updateInstanceStyle = updateInstanceStyle;
    vm.updateFooter = updateFooter;
    vm.onCodeMirrorLoad = onCodeMirrorLoad;
    vm.onAdvancedStylingSave = onAdvancedStylingSave;
    vm.codeMirrorLibrary = 'codemirror';

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any changes in the global styles to inject stylesheets.
     */
    $scope.$watch(function globalStyleWatcher() {
        return Style.getCurrent(vm.LIST_KEY);
    }, _injectStylesheets);

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the instance style dialog closes, reset the current styles.
     *
     * @param {Event}  evt      The dialog close event.
     * @param {string} dialogId The id of the dialog that closes.
     */
    $scope.$on('lx-dialog__close-end', function onDialogClose(evt, dialogId) {
        if (vm.dialogId === dialogId) {
            Style.setCurrent(undefined);
        }
    });

    /**
     * When we navigate away from the Instance style page, ignore the changes since init / last save.
     */
    $scope.$on('$stateChangeStart', _resetStyle);

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const style = Style.getCurrent(vm.LIST_KEY);
        const instance = Instance.getInstance();
        const isCustomerStyle = Style.isCustomer(style, vm.LIST_KEY);

        _originalStyle = angular.fastCopy(style);

        const hasCustomerStyles = angular.isDefinedAndFilled(Style.displayList(vm.LIST_KEY)) && isCustomerStyle;
        const isInstanceWithCustomerStyles =
            angular.isDefinedAndFilled(instance.style) && style.uid === instance.style && isCustomerStyle;

        vm.styleId = style.uid;
        vm.instanceId = instance.id;
        vm.footer = style.properties.footer;
        vm.updatedHead = null;
        vm.unsavedAdvancedStylingModifications = false;
        vm.styleContext = 'instance';

        if (hasCustomerStyles && isInstanceWithCustomerStyles) {
            vm.styleContext = 'customer';
        }

        _initDefaultValues();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('InstanceStyleSidebarController', InstanceStyleSidebarController);

/////////////////////////////

export { InstanceStyleSidebarController };
