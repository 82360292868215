import React from 'react';

import uniqueId from 'lodash/uniqueId';

import { contentView } from '@lumapps/contents/routes';
import { extended } from '@lumapps/notifications';
import { actions as notificationActions } from '@lumapps/notifications/ducks/slice';
import { Dispatch, GetFrontOfficeState } from '@lumapps/redux/types';
import { isLayoutEnabled as isLayoutEnabledSelector } from '@lumapps/widget-layout/ducks/selectors';

import {
    NotificationActions,
    NotificationContent,
    NotificationFigure,
} from '../components/DistributeContentPublishedNotification';

const DOMAIN = 'publishedContentDistributeNotification';

interface ShowContentPublishedDistributeNotficationOptions {
    /** id of the content */
    id: string;
    /**
     * whether the content is v2 compatible or not (to avoid referring on genesis
     * and losing ?distribute=true query param during redirection)
     */
    isV2Compatible?: boolean;
    /** slug of the content */
    slug: string;
}

/**
 * Thunk used in legacy designer to display an extended notification when content is created
 * The extended notification includes a link open distribute content form in FO
 */
export const showContentPublishedDistributeNotfication =
    (
        notificationTitle: string,
        { id: contentId, isV2Compatible, slug }: ShowContentPublishedDistributeNotficationOptions,
    ) =>
    async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
        const notificationId = uniqueId(DOMAIN);
        const state = getState();

        const isLayoutEnabled = isLayoutEnabledSelector(state);

        const contentRoute = contentView({
            to: {
                id: contentId,
                slug,
                query: { distribute: 'true' },
                isV2Compatible,
            },
            isLayoutEnabled,
        });

        const options = {
            id: notificationId,
            title: notificationTitle,
            figure: <NotificationFigure />,
            content: <NotificationContent />,
            actions: (
                <NotificationActions
                    route={contentRoute}
                    // when clicking the link, we also want to hide THIS notification
                    onClick={() => dispatch(notificationActions.hide({ id: notificationId }))}
                />
            ),
        };

        dispatch(extended(options));
    };
