import get from 'lodash/get';
import includes from 'lodash/includes';
import pick from 'lodash/pick';

import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { angularApi } from '@lumapps/router/routers';
import { adminContentTemplates } from '@lumapps/content-templates/routes';
import { adminCommunityTemplate } from '@lumapps/community-templates/routes';
import { rootAdmin, instanceSettings } from '@lumapps/navigation/routes';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { isLayoutEnabled as isLayoutEnabledSelector } from '@lumapps/widget-layout/ducks/selectors';
import { contentView } from '@lumapps/contents/routes';
import { createUrl } from '@lumapps/router/utils';
import { adminContentList } from '@lumapps/content-lists/routes';
import { spaceView } from '@lumapps/spaces/routes';
import { SpaceViews } from '@lumapps/spaces/types';

/////////////////////////////

function ContentSidebarController(
    $compile,
    $q,
    $rootScope,
    $injector,
    $scope,
    $state,
    $stateParams,
    $timeout,
    Cell,
    Community,
    CommunityTemplates,
    Config,
    Content,
    ContentForm,
    Customer,
    Header,
    Features,
    InitialSettings,
    Instance,
    LxDialogService,
    LxNotificationService,
    MainNav,
    Metadata,
    ReduxStore,
    Revision,
    Row,
    Template,
    Translation,
    User,
    UserAccess,
    Utils,
    Widget,
    resolveCustomContentType,
) {
    'ngInject';

    // This is here to trick the linter into thinking that the blocking resolve injection is used.
    angular.noop(resolveCustomContentType);

    /////////////////////////////

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The delay after which we should hide the loader.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _HIDE_LOADER_TIMEOUT = 100;

    /**
     * A DOM element that darkens the rest of the page when the loader is displayed.
     *
     * @type {Element}
     */
    let _appFilter;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Current active tab.
     *
     * @type {number}
     */
    vm.activeTab = 0;

    /**
     * Alias to the CONTENT_TYPES constant.
     *
     * @type {Array}
     */
    vm.contentTypes = InitialSettings.CONTENT_TYPES;

    /**
     * If content settings are juste readable, user can edit widget but not the sidebar.
     *
     * @type {boolean}
     */
    vm.isWritable = false;

    /**
     * Content global settings are opened or not.
     *
     * @type {boolean}
     */
    vm.globalSettingsView = true;

    /**
     * Indicates if we are creating/editing a community template.
     *
     * @type {boolean}
     */
    vm.isCommunityTemplate = false;

    /**
     * The navigation items for the navigation manager.
     *
     * @type {Object}
     */
    vm.navigationItems = {};

    /**
     * Wether to display the publish dialog or not.
     * We don't use here LumX dialog because we need the controller inside the dialog to be intialized
     * wether the dialog is opened or not.
     *
     * @type {boolean}
     */
    vm.isPublishDialogOpen = false;

    /**
     * Whether to display the react add to navigation dialog or not.
     *
     * @type {boolean}
     */
    vm.isReactAddToNavDialogOpen = false;


    /**
     * The display mode of the publish dialog.
     * Changes dialog title and actions.
     * 
     * @type {'review' | 'preview' | 'draft' | 'settings'}
     */
    vm.publishDialogMode = 'settings';

    /**
     * Revision mode tab is active or not.
     *
     * @type {boolean}
     */
    vm.revisionMode = false;

    /**
     * Dialog ID for the workflow refuse dialog.
     *
     * @type {string}
     */
    vm.workflowRefuseDialogId = 'workflow-refuse-dialog';

    /**
     * Dialog ID for the workflow refused message dialog.
     *
     * @type {string}
     */
    vm.workflowRefusedMessageDialogId = 'workflow-refused-message-dialog';

    vm.codeMirrorLibrary = 'codemirror';

    /////////////////////////////

    vm.Cell = Cell;
    vm.CommunityTemplates = CommunityTemplates;
    vm.Config = Config;
    vm.Content = Content;
    vm.Features = Features;
    vm.LxDialogService = LxDialogService;
    vm.Revision = Revision;
    vm.Row = Row;
    vm.Template = Template;
    vm.Translation = Translation;
    vm.User = User;
    vm.UserAccess = UserAccess;
    vm.Utils = Utils;
    vm.Widget = Widget;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the admin url.
     *
     * @return {string} The base url.
     */
    function getContentListAdminUrl() {
        const route = adminContentList(vm.Content.getCurrent().customContentType);
        const adminUrl = createUrl(route);
        return adminUrl;
    }

    vm.contentListAdminUrl = getContentListAdminUrl();

    /**
     * When code mirror has loaded, we need to reinitialize Froala so that it uses the CodeMirror global variable
     */
    function onCodeMirrorLoad() {
        const FroalaService = $injector.get('FroalaService');
        FroalaService.init();
    }

    vm.onCodeMirrorLoad = onCodeMirrorLoad;

    /**
     * Add a new navigation item representing the current content to the main navigation.
     */
    function _addContentToMainNav() {
        if (MainNav.is.initializing || angular.isUndefinedOrEmpty($stateParams.parentNavUuid)) {
            return;
        }

        // We want to create a new content and place it under a specific page in the navigation.
        MainNav.setItemIsDragged(false);

        const parentNavElement = MainNav.findNavItemById($stateParams.parentNavUuid);

        const newNavElement = angular.extend(
            angular.fastCopy(MainNav.copyElement(undefined, Content.getCurrent(), parentNavElement)),
            {
                $isNew: true,
                parentUuid: $stateParams.parentNavUuid,
            },
        );

        // Needed for the helper text in the navigation manager.
        Content.getCurrent().parentTitle = parentNavElement.title;

        if (angular.isUndefinedOrEmpty(newNavElement.title)) {
            newNavElement.title = {};
            newNavElement.title[Translation.inputLanguage] = Translation.translate('NEW_CONTENT_TITLE_FALLBACK');
        }

        vm.navigationItems = angular.fastCopy({
            customerId: Customer.getCustomerId(),
            instanceId: Instance.getCurrentInstanceId(),
            items: [
                {
                    items: [newNavElement],
                    lang: Translation.inputLanguage,
                },
            ],
        });
    }

    /**
     * Clean anything that needs to be cleaned in the global widget when checking for changes:
     *     - remove any empty array;
     *     - remove any empty object;
     *     - remove any "null" or "undefined";
     *     - remove any empty string;
     *     - remove any "false" object;
     *     - remove any styles if they have been backwarded;
     *     - remove useless properties;
     *
     * It is highly recommanded to pass a copy (angular.fastCopy) of the real widget and to use these copies to
     * compare changes as the clean function will mutate your widget.
     *
     * @param {Object} widget The widget to clean.
     */
    function _cleanForHasChanges(widget) {
        if (angular.isUndefinedOrEmpty(widget)) {
            return;
        }

        const propertiesToDelete = [
            'isBackwardedStyle',
            'authorDetails',
            'createdAt',
            'key',
            'stylesMigrated',
            'updatedAt',
            'updatedByDetails',
        ];

        angular.forEach(widget, function forEachWidgetProperties(value, property) {
            if (
                includes(propertiesToDelete, property) ||
                // This will remove all the $XXX properties (usually angular related stuff).
                (angular.isString(property) && property.charAt(0) === '$')
            ) {
                delete widget[property];

                return;
            }

            if (angular.isObject(value) && angular.isDefinedAndFilled(value)) {
                _cleanForHasChanges(value);
            }

            if (angular.isUndefinedOrEmpty(value) || value === false) {
                delete widget[property];
            }
        });
    }

    /**
     * Close component settings.
     */
    function _closeComponentSettings() {
        vm.globalSettingsView = true;

        Row.resetRow();
        Cell.resetCell();
        Widget.resetWidget();

        Header.isActive = false;
    }

    /**
     * Reformat the navigationItems object into parentsNavigation so the backend can save the navigation and
     * the content at the same time.
     *
     * Expected object is in the following format.
     *
     * [{
     *      deleted: [],
     *      items: [{
     *          parentId: parentNavItemId,
     *          parentUuid: parentNavItemUuid,
     *          sortOrder: 3,
     *          uuid: XXX,
     *      }],
     *      lang: 'fr',
     * }].
     *
     * @return {Array} A parentsNavigation array. See above on how to format it.
     */
    function _getParentsNavigationFromNavigationItems() {
        // If no items are being added, just return the deleted ones (if any).
        if (angular.isUndefinedOrEmpty(vm.navigationItems.items)) {
            return [
                {
                    deleted: vm.navigationItems.deleted || [],
                    items: [],
                    lang: Translation.inputLanguage,
                },
            ];
        }

        // Remap the items to be added and send back the potential deleted items as well.
        return vm.navigationItems.items.map(function forEachNavItem(navItem) {
            if (angular.isUndefinedOrEmpty(navItem.items)) {
                return false;
            }

            return {
                deleted: vm.navigationItems.deleted || [],
                items: navItem.items.map(function forEachItem(item) {
                    if (!item.$isNew) {
                        return item;
                    }

                    return angular.extend(pick(item, 'id', 'parentId', 'parentUuid', 'sortOrder'), {
                        uuid: generateUUID(),
                    });
                }),
                lang: navItem.lang,
            };
        });
    }

    /**
     * Hide revision loader.
     */
    function _hideLoader() {
        $timeout(function delayToRemoveElement() {
            _appFilter.remove();
        }, _HIDE_LOADER_TIMEOUT);
    }

    /**
     * Is Component published ?
     *
     * @return {boolean} If the component is live or not.
     */
    function _isComponentLive() {
        const currentContent = Content.getCurrent();
        if (angular.isUndefinedOrEmpty(currentContent)) {
            return false;
        }

        if (currentContent.type === Config.AVAILABLE_CONTENT_TYPES.COMMUNITY) {
            return true;
        }

        const expired = angular.isDefinedAndFilled(currentContent.endDate) && moment().isAfter(currentContent.endDate);
        const planned =
            angular.isDefinedAndFilled(currentContent.startDate) && moment().isBefore(currentContent.startDate);

        return (
            get(currentContent, 'status', '').toLowerCase() === Config.CONTENT_STATUS.LIVE.value.toLowerCase() &&
            !expired &&
            !planned
        );
    }

    /**
     * Manage component settings on component delete.
     *
     * @param {Object} evt           The event handled.
     * @param {string} componentType The component type (could be 'cell', 'row' or 'widget').
     */
    function _manageComponentSettings(evt, componentType) {
        const currentContent = Content.getCurrent();

        if (componentType === 'widget') {
            Widget.resetWidget();
            Cell.isActive = true;
        } else if (componentType === 'cell') {
            Widget.resetWidget();
            Cell.resetCell();

            if (angular.isUndefinedOrEmpty(Row.getCurrent().cells.length)) {
                Row.setCurrent(currentContent.template.components[0]);
            }

            Row.isActive = true;
        } else if (componentType === 'row') {
            Widget.resetWidget();
            Cell.resetCell();
            Row.setCurrent(currentContent.template.components[0]);
        }
    }

    /**
     * Open component settings on component set.
     */
    function _openComponentSettings() {
        vm.globalSettingsView = false;
    }

    /**
     * Save the content.
     *
     * @param {boolean}  asDraft Indicates if we want to save the content as draft, or save it and publish it.
     * @param {Function} [cb]    The callback to excecute.
     */
    function _save(asDraft, cb, forPreview) {
        cb = cb || angular.noop;

        if (Features.hasFeature('new-simple-template-experience') && Content.getCurrent().template.basicMode) {
            $scope.$broadcast('save-content', asDraft);
            if (!ContentForm.checkContent()) {
                if(asDraft) {
                    vm.openPublishDialog(forPreview ? 'preview' : 'draft');
                } else {
                    vm.openPublishDialog('review');
                }
            }

            cb();
        } else {
            if (!vm.globalSettingsView) {
                _closeComponentSettings();
            }

            Utils.waitForAndExecute('.sidebar-content--global-settings', function waitComponentSettingsClosed() {
                $scope.$broadcast('save-content', asDraft);

                cb();
            });
        }
    }

    /**
     * Show loader.
     */
    function _showLoader() {
        const loader = $compile('<lx-progress lx-variant="circular" lx-theme="dark"></lx-progress>')($scope);

        _appFilter = angular.element('<div/>', {
            class: 'app-filter app-filter--designer',
        });

        _appFilter.append(loader).appendTo('body');
    }

    /**
     * Update the content status callback.
     *
     * @param {Object} response A content.
     */
    function _updateContentStatusSuccessCallback(response) {
        if (angular.isDefined(response) && angular.isDefinedAndFilled(response.status)) {
            Content.setCurrent(response);

            let { status } = response;

            // Multiple actions can lead to a draft content.
            if (status === 'DRAFT' && Content.originalContent.status === 'ARCHIVE') {
                status = 'UNARCHIVE';
            } else if (status === 'DRAFT' && Content.originalContent.status === 'LIVE') {
                status = 'UNPUBLISH';
            }

            LxNotificationService.success(Translation.translate(`CONTENT_CHANGE_STATUS_${status}_SUCCESS`));

            Content.originalContent = angular.fastCopy(response);

            // Check if the user is still allowed to edit the content.
            // If not, redirect him to the admin panel.
            const content = Content.getCurrent();

            if (angular.isDefinedAndFilled(content.startDate) && angular.isUndefinedOrEmpty(content.startDateInput)) {
                content.startDateInput = {
                    date: moment.utc(content.startDate).local().format(),
                };
            }

            if (angular.isDefinedAndFilled(content.endDate) && angular.isUndefinedOrEmpty(content.endDateInput)) {
                content.endDateInput = {
                    date: moment.utc(content.endDate).local().format(),
                };
            }

            if (
                angular.isDefinedAndFilled(content.featuredStartDate) &&
                angular.isUndefinedOrEmpty(content.featuredStartDateInput)
            ) {
                content.featuredStartDateInput = {
                    date: moment.utc(content.featuredStartDate).local().format(),
                };
            }

            if (
                angular.isDefinedAndFilled(content.featuredEndDate) &&
                angular.isUndefinedOrEmpty(content.featuredEndDateInput)
            ) {
                content.featuredEndDateInput = {
                    date: moment.utc(content.featuredEndDate).local().format(),
                };
            }

            Content.get(
                {
                    action: 'PAGE_EDIT',
                    ignore403: true,
                    uid: content.id,
                },
                undefined,
                function onContentGetError() {
                    $state.go('app.admin.content', {
                        uid: content.customContentType,
                    });
                },
            );

            MainNav.init();
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Before asking for a validation, we check if the content has to be saved.
     */
    function askToValidate() {
        if (Content.hasChanges()) {
            vm.saveContent().then(function onSaveSuccess() {
                vm.updateContentStatus(vm.Config.CONTENT_STATUS.TO_VALIDATE.value);
            });
        } else {
            vm.updateContentStatus(vm.Config.CONTENT_STATUS.TO_VALIDATE.value);
        }
    }

    /**
     * Get URL to go back in react context.
     */
    function getBackToTemplateURl(customContentType) {
        return angularApi.getUrl(adminContentTemplates(customContentType));
    }

    /**
     * Close designer and go back to content admin or template admin.
     */
    function backToContent() {
        let currentContent = Content.getCurrent();

        if (Content.originalContent.id !== currentContent.id) {
            Content.setCurrent(Content._formatServerObjectToClient(Content.originalContent));
            currentContent = Content.getCurrent();
        }
        const currentAction = Content.getAction();

        const { id, renderingType } = currentContent;

        if (currentContent.type === 'template') {
            if (currentAction === 'edit') {
                const customContentType = currentContent.template
                    ? currentContent.template.customContentType
                    : currentContent.customContentType;

                if (angular.isDefinedAndFilled(customContentType)) {
                    angularApi.redirect(adminContentTemplates(customContentType));
                } else if (UserAccess.canManageInstanceSettings()) {
                    angularApi.redirect(instanceSettings());
                } else {
                    angularApi.redirect(rootAdmin());
                }
            } else if (currentAction === 'create') {
                const { customContentType } = $stateParams;
                angularApi.redirect(adminContentTemplates(customContentType));
            }
        } else if (currentAction === 'edit' && _isComponentLive()) {
            if (currentContent.type === InitialSettings.CONTENT_TYPES.COMMUNITY && renderingType !== 'space') {
                if ($stateParams.isTemplate) {
                    angularApi.redirect(adminCommunityTemplate());
                    return;
                }

                let view = $stateParams.subTemplate || 'dashboard';
                view = view === 'post' ? 'dashboard' : view;

                if (view === 'dashboard' && !get(currentContent, 'properties.hasDashboard', false)) {
                    view = undefined;
                }

                // Use to redirect User to the right view after closing the sidebar.
                $state.go('app.front.community', {
                    identifier: $state.params.postId,
                    slug: Translation.translate(currentContent.slug),
                    view: view || 'posts',
                });

                return;
            }

            /* Try to keep the previous slug, to prevent the breadcrumb from being empty.
             * The content of the content may have been updated, so the previous slug have to be updated.
             * If the previous slug is defined, keep it except for the content slug and happen the content slug.
             * Exemple: abc/xyz/my-page -> abc/xyz/my-page-updated (instead of onle my-page-updated).
             */
            let slug = '';

            if (angular.isDefinedAndFilled($stateParams.previousSlug)) {
                slug += $stateParams.previousSlug.substring(0, $stateParams.previousSlug.lastIndexOf('/'));
                slug += angular.isDefinedAndFilled(slug) ? '/' : '';
            }

            slug += Translation.translate(currentContent.slug);
            const { isV2Compatible } = currentContent.template;
            const state = ReduxStore.store.getState();
            const isLayoutEnabled = isLayoutEnabledSelector(state);

            if (renderingType === 'space') {
                angularApi.redirect(spaceView({ params: { slug, id, view: SpaceViews.home } }));
            } else if (isLayoutEnabled && isV2Compatible) {
                const isHomePage = id === getCurrentInstance(state)?.homePage;
                // Redirect to V2 if FF enabled and content compatible.
                angularApi.redirect(
                    contentView({
                        isLayoutEnabled,
                        to: { id, slug, isV2Compatible, isHomePage },
                    }),
                );
            } else {
                // Redirect to V1.
                $state.go('app.front.content-get', {
                    slug,
                });
            }
        } else if (currentAction === 'create' || !_isComponentLive()) {
            if (currentContent.type === InitialSettings.CONTENT_TYPES.DIRECTORY) {
                $state.go('app.admin.module');
            } else if (currentContent.type === InitialSettings.CONTENT_TYPES.COMMUNITY) {
                angularApi.redirect(adminCommunityTemplate());
            } else {
                $state.go('app.admin.content', {
                    uid: currentContent.customContentType,
                });
            }
        }
    }

    /**
     * Can manage basic designer.
     *
     * @return {boolean} If the user can access or not to the basic designer.
     */
    function canManageBasicDesigner() {
        return (
            Content.getCurrent().type !== 'template' &&
            UserAccess.canManageInstanceSettings() &&
            Content.getViewMode() === 'basic'
        );
    }

    /**
     * Can manage simple designer.
     *
     * @return {boolean} If the user can access or not to the simple designer.
     */
    function canManageSimpleDesigner() {
        const content = Content.getCurrent();

        return (
            content.type !== 'template' &&
            content.type !== vm.contentTypes.COMMUNITY &&
            (!content.template.fixedWidgets || UserAccess.canManageInstanceSettings())
        );
    }

    /**
     * Delete content.
     */
    function deleteContent() {
        $scope.$broadcast('delete-content');
    }

    /**
     * Disable basic designer.
     */
    function disableBasicDesigner() {
        Content.setViewMode('default');

        Content.getCurrent().template.fixedLayout = false;
        Content.getCurrent().template.fixedWidgets = false;
        Content.getCurrent().template.basicMode = false;
    }

    /**
     * Display navigation manager.
     *
     * @return {boolean} If the user can access or not to the navigation manager.
     */
    function displayNavigationManager() {
        const currentContent = Content.getCurrent();

        const isTemplate = $stateParams.type === 'template';
        const isStatusLive = currentContent.status === Config.CONTENT_STATUS.LIVE.value;
        const isStatusDraft = currentContent.status === Config.CONTENT_STATUS.DRAFT.value;
        const canDropMenu = UserAccess.isUserAllowed('MENU_DROP', {
            customContentTypeId: currentContent.customContentType,
        });
        const canEditMenu = UserAccess.isUserAllowed('MENU_EDIT');

        const canDropOrEdit = canDropMenu || canEditMenu;

        return (
            !isTemplate &&
            currentContent.type !== Config.AVAILABLE_CONTENT_TYPES.COMMUNITY &&
            (isStatusLive || (isStatusDraft && !angular.isUndefinedOrEmpty(currentContent.id))) &&
            canDropOrEdit
        );
    }

    /**
     * Display refuse message.
     *
     * @return {boolean} If we display or not the refuse message.
     */
    function displayRefuseMessage() {
        const currentContent = Content.getCurrent();

        return (
            currentContent.status === Config.CONTENT_STATUS.DRAFT.value &&
            angular.isDefined(currentContent.properties) &&
            angular.isDefinedAndFilled(currentContent.properties.publicationError)
        );
    }

    /**
     * Enable basic designer.
     */
    function enableBasicDesigner() {
        Content.setViewMode('basic');

        Content.getCurrent().template.fixedLayout = true;
        Content.getCurrent().template.fixedWidgets = true;
        Content.getCurrent().template.basicMode = true;
    }

    /**
     * Manage the update of content status.
     *
     * @param {string}  contentStatus     The future new status for the content.
     * @param {string}  message           The reason to update the content status.
     * @param {boolean} closeRefuseDialog If we need to close the refuse dialog or not.
     */
    function manageUpdateContentStatus(contentStatus, message, closeRefuseDialog) {
        if (contentStatus === Config.CONTENT_STATUS.TO_VALIDATE.value) {
            Content.setViewMode('locked');
        } else {
            if (Content.getCurrent().template.fixedLayout && Content.getCurrent().template.fixedWidgets) {
                Content.setViewMode(Content.getCurrent().template.basicMode ? 'basic' : 'simple');
            } else {
                Content.setViewMode('default');
            }
        }

        Content.updateStatus(
            Content.getCurrent().id,
            contentStatus,
            message,
            function updateStatusResp(response) {
                if (angular.isDefined(response) && angular.isDefinedAndFilled(response.status)) {
                    _updateContentStatusSuccessCallback(response);

                    vm.publicationError = undefined;

                    if (closeRefuseDialog) {
                        LxDialogService.close(vm.workflowRefuseDialogId);
                    }
                }
            },
            Utils.displayServerError,
        );
    }

    /**
     * Open the publishing options dialog in simple template.
     * 
     * mode = 'review': Display review title and publish button + back to edit button
     * 
     * mode = 'preview': Display default title and preview button + back to edit button
     * 
     * mode = 'draft': Display default title and save as draft button + back to edit button
     * 
     * mode = 'settings': Display review title and publish button
     *
     * @param {'review' | 'preview' | 'draft' | 'settings'} mode The variant mode of the publish dialog.
     * 
     */
    function openPublishDialog(mode) {
        vm.isPublishDialogOpen = true;
        vm.publishDialogMode = mode || 'settings';
    }

    /**
     * Close the publishing options dialog in simple template.
     */
    function closePublishDialog(cancel) {
        vm.isPublishDialogOpen = false;
        if (cancel) {
            $scope.$broadcast('save-canceled');
        }
    }

    /**
     * Open the react add to navigation dialog dialog.
     */
    function openNavigationManager() {
        vm.isReactAddToNavDialogOpen = true;
    }

    /**
     * Close the publishing options dialog in simple template.
     */
    function closeNavigationManager() {
        vm.isReactAddToNavDialogOpen = false;
    }

    /**
     * Save content.
     *
     * @param  {boolean} asDraft Indicates if we want to save the content as draft, or save it and publish it.
     * @return {Promise} The promise that will resolve when the save is completed.
     */
    function saveContent(asDraft, forPreview) {
        if (Features.hasFeature('new-simple-template-experience') && Content.getCurrent().template.basicMode) {
            closePublishDialog();
        }

        return $q(function saveContentDeferred(resolve) {
            const isCommunityTemplate =
                $stateParams.contentType === InitialSettings.CONTENT_TYPES.COMMUNITY &&
                ($stateParams.type === 'template' || $stateParams.isTemplate);

            const content = Content.getCurrent();

            if (
                angular.isDefinedAndFilled(content.metadata) &&
                content.type === InitialSettings.CONTENT_TYPES.COMMUNITY
            ) {
                Metadata.formatMetadataForServer(content);
            }

            if (isCommunityTemplate) {
                _save(false);
                resolve();

                return;
            }

            if (angular.isDefinedAndFilled([vm.navigationItems.deleted, vm.navigationItems.items], 'some')) {
                content.parentsNavigation = _getParentsNavigationFromNavigationItems();
            }

            _save(asDraft, null, forPreview);
            resolve();
        });
    }

    /**
     * Set the right view mode and the right template mode according to the given contribution mode.
     *
     * @param {string} mode The contribution.
     */
    function setContributionMode(mode) {
        _closeComponentSettings();

        if (mode === 'writing') {
            Content.setViewMode('basic');

            Content.getCurrent().template.fixedLayout = true;
            Content.getCurrent().template.fixedWidgets = true;

            $rootScope.$broadcast('contribution-mode', 'writer');
        } else if (mode === 'widget') {
            Content.setViewMode('default');

            Content.getCurrent().template.fixedLayout = true;
            Content.getCurrent().template.fixedWidgets = false;

            $rootScope.$broadcast('contribution-mode', 'builder');
        } else if (mode === 'layout') {
            Content.setViewMode('default');

            Content.getCurrent().template.fixedLayout = false;
            Content.getCurrent().template.fixedWidgets = false;

            $rootScope.$broadcast('contribution-mode', 'expert');
        }
    }

    function getContributionModeIcon() {
        if (Content.getViewMode() === 'locked') {
            return 'eye-outline';
        } else if (Content.getViewMode() === 'basic') {
            return 'pencil-outline';
        } else if (Content.getViewMode() === 'default' && Content.getCurrent().template.fixedLayout) {
            return 'view-grid-plus-outline';
        } else if (Content.getViewMode() === 'default' && !Content.getCurrent().template.fixedLayout) {
            return 'view-compact-outline';
        }
    }

    function getContributionModeLabel() {
        if (Content.getViewMode() === 'locked') {
            return Translation.translate('SIMPLE_DESIGNER_MODE.READER_MODE_LABEL');
        } else if (Content.getViewMode() === 'basic') {
            return Translation.translate('SIMPLE_DESIGNER_MODE.WRITER_MODE_LABEL');
        } else if (Content.getViewMode() === 'default' && Content.getCurrent().template.fixedLayout) {
            return Translation.translate('SIMPLE_DESIGNER_MODE.BUILDER_MODE_LABEL');
        } else if (Content.getViewMode() === 'default' && !Content.getCurrent().template.fixedLayout) {
            return Translation.translate('SIMPLE_DESIGNER_MODE.EXPERT_MODE_LABEL');
        }
    }

    /**
     * Update the content status.
     *
     * @param {string} contentStatus The future content status.
     */
    function updateContentStatus(contentStatus) {
        LxNotificationService.confirm(
            Translation.translate(`CONTENT_SET_TO_${contentStatus}`),
            Translation.translate(`CONTENT_SET_TO_${contentStatus}_DESCRIPTION`),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            function afterConfirm(answer) {
                if (answer) {
                    vm.manageUpdateContentStatus(contentStatus);

                    $rootScope.$broadcast('content-status-updated', contentStatus);
                }
            },
        );
    }

    /**
     * Open workflow refuse dialog.
     */
    function workflowRefuse() {
        Utils.waitForAndExecute(`#${vm.workflowRefuseDialogId}`);
    }

    /**
     * Whether the add to navigation button should be enabled
     * Only enable the button when the content has been published.
     * @return {boolean}
     */
    function getIsAddToNavigationEnabled() {
        return Content.getCurrent().status === Config.CONTENT_STATUS.LIVE.value;
    }

    /**
     * Whether we want to display the widget's settings or not in writer mode
     * @param {Object} currentWidget
     * @return {boolean}
     */
    function areSettingsAvailableInWriterMode(currentWidget) {
        const compatibleWidgetTypes = ['remote', 'links', 'iframe', 'content-list', 'video'];

        return vm.Content.getViewMode() !== 'basic' || (currentWidget && includes(compatibleWidgetTypes, currentWidget.widgetType) && vm.Features.hasFeature('new-simple-template-experience'))
    }

    /**
     * Save content as draft and return the new content in a promise.
     */
    async function saveContentDraft() {
        saveContent(true, true);
        return new Promise((resolve, reject) => {

            $scope.$on('save-canceled', () => {
                reject();
            })
            $scope.$on('content-saved', (evt, err) => {
                if (err) reject(err)
                else resolve(Content.getCurrent());
            });
        });
    }

    /**
     * Check if current content is a standard content (not a directory, community, etc.)
     */
    function isStandardContent() {
        return vm.Content.getCurrent().type === vm.contentTypes.PAGE || vm.Content.getCurrent().type === vm.contentTypes.NEWS || vm.Content.getCurrent().type === vm.contentTypes.CUSTOM
    }

    /////////////////////////////

    vm.askToValidate = askToValidate;
    vm.backToContent = backToContent;
    vm.canManageBasicDesigner = canManageBasicDesigner;
    vm.canManageSimpleDesigner = canManageSimpleDesigner;
    vm.deleteContent = deleteContent;
    vm.disableBasicDesigner = disableBasicDesigner;
    vm.displayNavigationManager = displayNavigationManager;
    vm.displayRefuseMessage = displayRefuseMessage;
    vm.enableBasicDesigner = enableBasicDesigner;
    vm.getBackToTemplateURl = getBackToTemplateURl;
    vm.manageUpdateContentStatus = manageUpdateContentStatus;
    vm.openPublishDialog = openPublishDialog;
    vm.closePublishDialog = closePublishDialog;
    vm.saveContent = saveContent;
    vm.saveContentDraft = saveContentDraft;
    vm.setContributionMode = setContributionMode;
    vm.getContributionModeIcon = getContributionModeIcon;
    vm.getContributionModeLabel = getContributionModeLabel;
    vm.updateContentStatus = updateContentStatus;
    vm.workflowRefuse = workflowRefuse;
    vm.openNavigationManager = openNavigationManager;
    vm.closeNavigationManager = closeNavigationManager;
    vm.getIsAddToNavigationEnabled = getIsAddToNavigationEnabled;
    vm.areSettingsAvailableInWriterMode = areSettingsAvailableInWriterMode;
    vm.isStandardContent = isStandardContent;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    $scope.$on('navigation-picker__open-start', _addContentToMainNav);

    /**
     * Initialize MainNav if the input language has changed.
     */
    $scope.$on('inputLanguage', function onInputLanguage() {
        MainNav.init();
    });

    /**
     * Open component settings when a component is selected.
     */
    $scope.$on('designer-component__set', _openComponentSettings);

    /**
     * Manage component settings when a component is deleted.
     */
    $scope.$on('designer-component__delete', _manageComponentSettings);

    /**
     * Close component settings when component is unselected.
     */
    $scope.$on('close-component-settings', _closeComponentSettings);

    /**
     * When the content has been saved, cleanup the navigation elements.
     *
     * @param {Event}  evt   The content saved event.
     * @param {Object} [err] The save error (if any).
     */
    $scope.$on('content-saved', function onContentSaved(evt, err) {
        if (angular.isDefinedAndFilled(err)) {
            return;
        }

        vm.navigationItems = {};
        delete Content.getCurrent().parentsNavigation;
    });

    /**
     * Show a loader in the sidebar if the showLoader event is fired.
     */
    $scope.$on('show-loader', _showLoader);

    /**
     * Hide the loader in the sidebar if the hideLoader event is fired.
     */
    $scope.$on('hide-loader', _hideLoader);

    /**
     * Action to leave the revision tab.
     */
    $scope.$on('exit-revision', function exitRevision() {
        vm.activeTab = 0;
    });

    $scope.$on('main-nav-loaded', _addContentToMainNav);

    /**
     * The controller is destroyed, cancel the watchers.
     */
    $scope.$on('$destroy', function onControllerDestroy() {
        ContentForm.resetContentForm();
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const currentContent = Content.getCurrent();

        if (!currentContent.properties) {
            currentContent.properties = {};
        }

        const community =
            $stateParams.contentType === InitialSettings.CONTENT_TYPES.COMMUNITY ? Community.getCurrent() : undefined;
        vm.isSpace = Community.isSpace(community);

        vm.isCommunityTemplate = Boolean(
            $stateParams.contentType === InitialSettings.CONTENT_TYPES.COMMUNITY &&
                ($stateParams.type === 'template' || $stateParams.isTemplate),
        );

        vm.isWritable =
            Content.getAction() === 'create' ||
            vm.isCommunityTemplate ||
            $stateParams.contentType !== InitialSettings.CONTENT_TYPES.COMMUNITY;

        $timeout(function delayToAddContentToMainNav() {
            _addContentToMainNav();
        });
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('ContentSidebarController', ContentSidebarController);

/////////////////////////////

export { ContentSidebarController };
